import cloneDeep from 'lodash/cloneDeep';

export const ENTITY_PART_TYPE_CUSTOM = 'Custom';
export const ENTITY_PART_TYPE_KNOWN = 'Known';
export const ENTITY_PART_TYPE_SEPARATOR = 'Separator';
export const ENTITY_PART_TYPE_PLACEHOLDER = 'Placeholder';
export const ENTITY_PART_TYPE_QUANTITY = 'Quantity';
export const ENTIYT_PART_TYPE_STEMSIZE = 'StemSize';
export const ENTITY_PART_TYPE_RATE = 'Rate';
export const ENTITY_PART_TYPE_COUNTRY = 'Country';
export const ENTITY_PART_TYPE_GAIN_ACCOUNT = 'GainAccount';
export const ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP = 'GainAccountGroup';
export const ENTITY_PART_TYPE_CUSTOM_ACCOUNT = 'CustomAccount';
export const ENTITY_PART_TYPE_DURATION = 'Duration';

export const DIRECTION_FORWARD = 1;
export const DIRECTION_BACKWARD = -1;

export const SEPARATOR_PLUS = '+';
export const SEPARATOR_FORWARD_SLASH = '/';
export const SEPARATOR_PLUS_FORWARD_SLASH = '+/';
export const ENTITY_SEPARATOR_CHARACTERS = [
    SEPARATOR_FORWARD_SLASH,
    SEPARATOR_PLUS,
];

export const DEFAULT_SEPARATOR_CHARACTER = ENTITY_SEPARATOR_CHARACTERS[0];

export const isKnownOrCountry = (partType) =>
    partType === ENTITY_PART_TYPE_KNOWN ||
    partType === ENTITY_PART_TYPE_COUNTRY;

export const deleteItemAtPosition = (parts, position) => {
    if (position >= 0 && position < parts.length) {
        parts.splice(position, 1);
    }
    return { parts };
};

export const isSeparator = (part) =>
    part && part.partType === ENTITY_PART_TYPE_SEPARATOR;

export const isEntity = (part) =>
    part &&
    (part.partType === ENTITY_PART_TYPE_CUSTOM ||
        part.partType === ENTITY_PART_TYPE_KNOWN ||
        part.partType === ENTITY_PART_TYPE_RATE);

export const normalizeSeparators = (
    partsToNormalize,
    createSeparatorEntityPart,
    defaultSeperatorCharacter
) => {
    const parts = cloneDeep(partsToNormalize);
    let i = 0;

    while (i < parts.length) {
        const [p1, p2] = parts.slice(i, i + 2);

        if (isSeparator(p1) && isSeparator(p2)) {
            parts.splice(i, 1);

            if (p1.value === SEPARATOR_PLUS && p2.value === SEPARATOR_PLUS) {
                parts[i] = createSeparatorEntityPart(SEPARATOR_PLUS);
            } else {
                parts[i] = createSeparatorEntityPart(SEPARATOR_FORWARD_SLASH);
            }
        } else if (!isSeparator(p1) && p2 && !isSeparator(p2)) {
            parts.splice(
                i + 1,
                0,
                createSeparatorEntityPart(
                    defaultSeperatorCharacter || DEFAULT_SEPARATOR_CHARACTER
                )
            );
            i++;
        } else {
            i++;
        }
    }

    return parts;
};

export const trimSeparators = (partsToTrim) => {
    const parts = cloneDeep(partsToTrim);
    while (isSeparator(parts[0])) {
        parts.splice(0, 1);
    }
    return parts;
};

export const trimTrailingSeparators = (partsToTrim) => {
    const parts = cloneDeep(partsToTrim);
    while (isSeparator(parts[parts.length - 1])) {
        parts.splice(parts.length - 1, 1);
    }
    return parts;
};

export const canAddSeparatorAt = (parts, position) => {
    return (
        parts.length !== 0 &&
        !isSeparatorAt(parts, position, DIRECTION_BACKWARD)
    );
};

export const dragAndDropPart = (
    partsToDragAndDrop,
    sourceIndex,
    targetIndex
) => {
    const parts = cloneDeep(partsToDragAndDrop);
    const source = parts[sourceIndex];
    if (targetIndex < sourceIndex) {
        parts.splice(targetIndex, 0, source);
        parts.splice(sourceIndex + 1, 1);
    } else {
        parts.splice(sourceIndex, 1);
        parts.splice(targetIndex - 1, 0, source);
    }

    let newParts = [];
    const separatorParts = parts.filter(
        (p) => p.partType === ENTITY_PART_TYPE_SEPARATOR
    );
    const partsWithoutSeparator = parts.filter(
        (p) => p.partType !== ENTITY_PART_TYPE_SEPARATOR
    );

    for (let i = 0; i < partsWithoutSeparator.length; i++) {
        newParts.push(partsWithoutSeparator[i]);

        const separator = separatorParts[i];

        if (separator) {
            newParts.push(separator);
        }
    }

    return newParts;
};

export const normalizeParts = (
    parts,
    createSeparatorEntityPart,
    defaultSeperatorCharacter
) => {
    const normalized = normalizeSeparators(
        parts,
        createSeparatorEntityPart,
        defaultSeperatorCharacter || DEFAULT_SEPARATOR_CHARACTER
    );
    return trimSeparators(normalized);
};

export const isSeparatorAt = (parts, position, direction) => {
    const nextPosition =
        direction === DIRECTION_BACKWARD ? position - 1 : position;
    return (
        parts.length > nextPosition &&
        nextPosition >= 0 &&
        isSeparator(parts[nextPosition])
    );
};

export const addPart = (
    parts,
    part,
    position,
    createSeparatorEntityPart,
    defaultSeperatorCharacter
) => {
    if (position === undefined) {
        position = parts.length;
    }

    const initialSize = parts.length;
    if (part.partType === ENTITY_PART_TYPE_SEPARATOR) {
        if (canAddSeparatorAt(parts, position)) {
            parts.splice(position, 0, part);
        }
    } else {
        parts.splice(position, 0, part);
    }

    const normalized = normalizeSeparators(
        parts,
        createSeparatorEntityPart,
        defaultSeperatorCharacter || DEFAULT_SEPARATOR_CHARACTER
    );
    const trimmed = trimSeparators(normalized);
    position += trimmed.length - initialSize;

    return {
        parts: trimmed,
        cursorPos: position,
    };
};
