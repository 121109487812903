import { createTheme } from '@mui/material/styles';
import { indigo, grey, teal } from '@mui/material/colors';

const appFonts = {
    CeraGR: ['Cera GR', 'Roboto', 'Cera GR Light', 'sans-serif'].join(','),
    NotoSans: [
        'Noto Sans',
        'Cera GR',
        'Roboto',
        'Cera GR Light',
        'sans-serif',
    ].join(','),
};

const appTheme = createTheme({
    palette: {
        primary: {
            main: indigo[600],
        },
        secondary: {
            main: teal[300],
        },
        info: {
            main: grey[700],
        },
    },
    typography: {
        fontFamily: appFonts.CeraGR,
    },
    components: {
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    '&, *': {
                        userSelect: 'none',
                        fontFamily: appFonts.NotoSans,
                    },
                },
            },
        },
    },
});

export default appTheme;
