import initialState from './initialState';
import * as types from '../actions/actionTypes';

const layoutReducer = (state = initialState.layouts, action) => {
    switch (action.type) {
        case types.LAYOUT_ON_LOAD:
            return {
                ...state,
                allLayouts: action.allLayouts,
                selectedLayout: action.selectedLayout,
            };
        case types.LAYOUT_ON_SELECT:
            return {
                ...state,
                allLayouts: action.allLayouts,
                selectedLayout: action.selectedLayout,
            };
        case types.LAYOUT_ON_UPDATE:
            return {
                ...state,
                allLayouts: action.allLayouts,
            };
        case types.LAYOUT_ON_ORDERS_GRID_OPTIONS_CHANGED:
            return {
                ...state,
                selectedLayout: {
                    ...state.selectedLayout,
                    areThereAnyUnsavedLayoutChanges:
                        action.areThereAnyUnsavedLayoutChanges,
                    orders: {
                        ...state.selectedLayout.orders,
                        currentOptions: action.currentOptions,
                        wereOptionsChanged: action.wereOptionsChanged,
                    },
                },
            };
        case types.LAYOUT_ON_FIXTURES_GRID_OPTIONS_CHANGED:
            return {
                ...state,
                selectedLayout: {
                    ...state.selectedLayout,
                    areThereAnyUnsavedLayoutChanges:
                        action.areThereAnyUnsavedLayoutChanges,
                    fixtures: {
                        ...state.selectedLayout.fixtures,
                        currentOptions: action.currentOptions,
                        wereOptionsChanged: action.wereOptionsChanged,
                    },
                },
            };
        case types.LAYOUT_ON_REVERT:
            return {
                ...state,
                selectedLayout: {
                    ...state.selectedLayout,
                    areThereAnyUnsavedLayoutChanges: false,
                    commonSettings: {
                        ...state.selectedLayout.commonSettings,
                        directionLogic: {
                            ...state.selectedLayout.commonSettings
                                .directionLogic,
                            currentState:
                                state.selectedLayout.commonSettings
                                    .directionLogic.initialState,
                        },
                        quantityFormat: {
                            ...state.selectedLayout.commonSettings
                                .quantityFormat,
                            currentState:
                                state.selectedLayout.commonSettings
                                    .quantityFormat.initialState,
                        },
                        defaultType: {
                            ...state.selectedLayout.commonSettings.defaultType,
                            currentState:
                                state.selectedLayout.commonSettings.defaultType
                                    .initialState,
                        },
                    },
                    fixtures: {
                        ...state.selectedLayout.fixtures,
                        currentOptions:
                            state.selectedLayout.fixtures.initialOptions,
                        wereOptionsChanged: false,
                    },
                    orders: {
                        ...state.selectedLayout.orders,
                        currentOptions:
                            state.selectedLayout.orders.initialOptions,
                        wereOptionsChanged: false,
                    },
                },
            };
        case types.LAYOUT_ON_SAVE:
            return {
                ...state,
                selectedLayout: {
                    ...state.selectedLayout,
                    areThereAnyUnsavedLayoutChanges: false,
                    commonSettings: {
                        ...state.selectedLayout.commonSettings,
                        directionLogic: {
                            ...state.selectedLayout.commonSettings
                                .directionLogic,
                            initialState:
                                state.selectedLayout.commonSettings
                                    .directionLogic.currentState,
                        },
                        quantityFormat: {
                            ...state.selectedLayout.commonSettings
                                .quantityFormat,
                            initialState:
                                state.selectedLayout.commonSettings
                                    .quantityFormat.initialState,
                        },
                        defaultType: {
                            ...state.selectedLayout.commonSettings.defaultType,
                            initialState:
                                state.selectedLayout.commonSettings.defaultType
                                    .initialState,
                        },
                    },
                    fixtures: {
                        ...state.selectedLayout.fixtures,
                        initialOptions:
                            state.selectedLayout.fixtures.currentOptions,
                        wereOptionsChanged: false,
                    },
                    orders: {
                        ...state.selectedLayout.orders,
                        initialOptions:
                            state.selectedLayout.orders.currentOptions,
                        wereOptionsChanged: false,
                    },
                },
            };
        case types.LAYOUT_ON_DIRECTION_LOGIC_CHANGED:
            return {
                ...state,
                selectedLayout: {
                    ...state.selectedLayout,
                    areThereAnyUnsavedLayoutChanges:
                        action.areThereAnyUnsavedLayoutChanges,
                    commonSettings: {
                        ...state.selectedLayout.commonSettings,
                        directionLogic: {
                            ...state.selectedLayout.commonSettings
                                .directionLogic,
                            currentState: action.currentDirectionLogic,
                        },
                    },
                },
            };
        case types.LAYOUT_ON_QUANTITY_FORMAT_CHANGED:
            return {
                ...state,
                selectedLayout: {
                    ...state.selectedLayout,
                    areThereAnyUnsavedLayoutChanges:
                        action.areThereAnyUnsavedLayoutChanges,
                    commonSettings: {
                        ...state.selectedLayout.commonSettings,
                        quantityFormat: {
                            ...state.selectedLayout.commonSettings
                                .quantityFormat,
                            currentState: action.currentQuantityFormat,
                        },
                    },
                },
            };
        case types.LAYOUT_ON_DEFAULT_TYPE_CHANGED:
            return {
                ...state,
                selectedLayout: {
                    ...state.selectedLayout,
                    areThereAnyUnsavedLayoutChanges:
                        action.areThereAnyUnsavedLayoutChanges,
                    commonSettings: {
                        ...state.selectedLayout.commonSettings,
                        defaultType: {
                            ...state.selectedLayout.commonSettings.defaultType,
                            currentState: action.currentDefaultType,
                        },
                    },
                },
            };
        default:
            return state;
    }
};

export default layoutReducer;
