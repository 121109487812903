import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GroupingToolPanel from './GroupingToolPanel';
import * as layoutActions from '../../../actions/layoutActions';
import { getDefaultOrdersRowGroupsSorting } from '_legacy/services/RowGroupsSortingService';
import { ORDER_GRID_TYPE } from '_legacy/constants/gridTypes';

export const GroupingOrdersToolPanel = (props) => {
    const ordersGridProps = {
        ...props,
        onGridOptionsChanged: props.actions.onOrdersGridOptionsChanged,
        currentOptions: props.currentOptions,
        getDefaultRowGroupsSorting: getDefaultOrdersRowGroupsSorting,
        gridType: ORDER_GRID_TYPE,
    };
    return <GroupingToolPanel {...ordersGridProps} />;
};

const mapStateToProps = (state) => {
    const props = {
        currentOptions: state.layouts.selectedLayout.orders.currentOptions,
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...layoutActions }, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupingOrdersToolPanel);
