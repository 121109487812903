import React, { Component } from 'react';
import ThirdPartyCompanyInput from '../inputs/ThirdPartyCompanyInputs';
import { KEY_TAB } from '_legacy/constants/keyboardCodes';
import CreateCompanyForm from 'components/modal/createCompanyForm/CreateCompanyForm';
import validateSelectedThirdPartyCompany from 'components/common/ThirdPartyCompany/ThirdPartyCompanyValidation';

class ThirdPartyCompanyEditor extends Component {
    constructor(props) {
        super(props);

        this.companyInputRef = React.createRef();

        if (this.props.value) {
            this.state = {
                selectedCompany: { ...this.props.value },
                isNewCompanyModalOpened: false,
            };
        } else {
            this.state = { isNewCompanyModalOpened: false };
        }
    }

    afterGuiAttached() {
        this.focus();
    }

    focus = () => {
        if (this.companyInputRef.current) {
            this.companyInputRef.current.focus();
        }
    };

    handleOnTab = () => {
        this.props.api.tabToNextCell();
    };

    handleOnTabBack = () => {
        this.props.api.tabToPreviousCell();
    };

    handleOnEnter = () => {
        this.props.stopEditing();
    };

    getValue = () => {
        return this.state.selectedCompany;
    };

    isPopup() {
        return true;
    }

    openNewCompanyModal = () => {
        this.setState({ isNewCompanyModalOpened: true });
    };

    closeNewCompanyModal = () => {
        this.setState({ isNewCompanyModalOpened: false });
    };

    submitCustomThirdPartyCompany = (company) => {
        this.setState(
            {
                isNewCompanyModalOpened: false,
                selectedCompany: company,
            },
            () => this.props.stopEditing()
        );
    };

    onCompanySelected = (company, keyCode, shift) => {
        if (
            !validateSelectedThirdPartyCompany(
                this.props.data,
                company,
                undefined
            )
        ) {
            return;
        }
        this.setState(
            {
                selectedCompany: company,
            },
            () => {
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;
                    default:
                        this.handleOnEnter();
                        break;
                }
            }
        );
    };

    handleInputCleared = () => {
        this.setState({ selectedCompany: null });
    };

    render(placeholderName) {
        return (
            <>
                <ThirdPartyCompanyInput
                    ref={this.companyInputRef}
                    value={this.state.selectedCompany}
                    onTab={this.handleOnTab}
                    onTabBack={this.handleOnTabBack}
                    onEnter={this.handleOnEnter}
                    onCompanySelected={this.onCompanySelected}
                    shouldSelectItemOnTab={true}
                    inputClass="ag-react-container"
                    initialChar={this.props.charPress}
                    onInputCleared={this.handleInputCleared}
                    placeholderName={placeholderName}
                    openNewCompanyModal={this.openNewCompanyModal}
                />
                {this.state.isNewCompanyModalOpened && (
                    <CreateCompanyForm
                        entityId={this.props?.node?.id}
                        open={this.state.isNewCompanyModalOpened}
                        onCancel={this.closeNewCompanyModal}
                        onSubmit={this.submitCustomThirdPartyCompany}
                    />
                )}
            </>
        );
    }
}

export default ThirdPartyCompanyEditor;
