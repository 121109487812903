//TODO : need to create separate isolation component to reduce duplication with LocationSelect component

import React, { Component } from 'react';
import Downshift from 'downshift';
import DownshiftActivityIndicator from './DownshiftActivityIndicator';
import FortDownshiftInput from './FortDownshiftInput';
import axios from 'axios';
import {
    KEY_BACKSPACE,
    KEY_DELETE,
    KEY_TAB,
    KEY_ENTER,
    KEY_LEFT,
    KEY_RIGHT,
} from '../../../constants/keyboardCodes';
import ThirdPartyCompanyApi from '../../../api/thirdPartyCompanyApi';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import {
    DIRECTION_FORWARD,
    DIRECTION_BACKWARD,
} from '../../../models/common/EntityPart';
import { CYPRESS_DATA_ATTRIBUTES } from 'constant';
import ThirdPartyCompanyClientCheck from 'components/common/ThirdPartyCompany/ThirdPartyCompanyClientCheck';
import ThirdPartyCompanySearchResult from 'components/common/ThirdPartyCompany/ThirdPartyCompanySearchResult';
import { defaultDebounceMs } from '_legacy/constants/inputBehaviours';

const { GRID_CELL_INPUT } = CYPRESS_DATA_ATTRIBUTES;

const CancelToken = axios.CancelToken;

class ThirdPartyBrokerInput extends Component {
    constructor(props) {
        super(props);
        this.reset = this.reset.bind(this);
        this.state = this.initialState(props);
        this.downshiftRef = React.createRef();
        this.inputRef = React.createRef();
        this.stateReducer = this.stateReducer.bind(this);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
        this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
        this.hasFocus = this.hasFocus.bind(this);
        this.cancelSource = null;

        this.searchBrokers = this.searchBrokers.bind(this);
        this.searchBrokers = debounce(this.searchBrokers, defaultDebounceMs);

        this.cancelSearch = this.cancelSearch.bind(this);
        this.cancelSearchRequests = this.cancelSearchRequests.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.state.inputValue) {
            this.setState({ isLoadingBrokers: true });
            this.cancelSearchRequests();
            this.searchBrokers(this.state.inputValue);
        }
    }

    componentDidMount() {
        if (this.state.initialSeparator) {
            this.props.onSeparatorEntered(this.props.initialChar);
        }
    }

    componentWillUnmount() {
        this.cancelSearch();
    }

    initialState = (props) => {
        let inputValue = '';
        let initialIsOpen = false;
        let initialSeparator = false;
        if (props.initialChar) {
            if (this.isSeparatorCharacter(props.initialChar)) {
                initialSeparator = true;
            } else {
                inputValue = props.initialChar;
                initialIsOpen = true;
            }
        }

        return {
            matchedBrokers: [],
            error: null,
            isDeleting: false,
            isLoadingBrokers: false,
            initialIsOpen,
            inputValue,
            initialSeparator,
        };
    };

    itemToString = (broker) => (broker ? broker.name : '');

    reset() {
        this.setState((state, props) => this.initialState(props));
    }

    isSeparatorCharacter(character) {
        if (this.props.seperatorCharacters) {
            return this.props.seperatorCharacters.indexOf(character) > -1;
        }

        return false;
    }

    searchBrokers(value) {
        if (value.length < 2) {
            this.setState({
                isLoadingBrokers: false,
                matchedBrokers: [],
                error: null,
            });
            return;
        }

        ThirdPartyCompanyApi.search(value, this.cancelSource.token)
            .then((results) => {
                this.setState({
                    isLoadingBrokers: false,
                    matchedBrokers: results,
                    error: null,
                });
            })
            .catch((error) => {
                console.log(error);
                if (!axios.isCancel(error)) {
                    this.setState({ error });
                }
            });
    }

    cancelSearchRequests() {
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }

        this.cancelSource = CancelToken.source();
    }

    cancelSearch() {
        if (this.searchBrokers.cancel) {
            this.searchBrokers.cancel();
        }

        this.cancelSearchRequests();
    }

    trimStart(character, string) {
        var startIndex = 0;

        while (string[startIndex] === character) {
            startIndex++;
        }

        return string.substr(startIndex);
    }

    stateReducer(state, changes) {
        switch (changes.type) {
            case Downshift.stateChangeTypes.changeInput:
                var valueToSearch = changes.inputValue.trim();

                if (this.props.onChange) {
                    this.props.onChange(valueToSearch.toUpperCase());
                }

                this.setState({ inputValue: changes.inputValue, error: null });

                var valueToDisplay = this.trimStart(' ', changes.inputValue);

                if (valueToSearch === '') {
                    this.cancelSearch();
                    changes.isOpen = false;
                    changes.inputValue = '';
                } else if (this.isSeparatorCharacter(valueToSearch)) {
                    this.cancelSearch();
                    changes.isOpen = false;
                    changes.inputValue = '';

                    this.props.onSeparatorEntered(valueToSearch.toUpperCase());

                    this.reset();
                } else {
                    this.setState({ isLoadingBrokers: true });

                    this.cancelSearchRequests();

                    this.searchBrokers(changes.inputValue);

                    changes.isOpen = true;
                    changes.inputValue = valueToDisplay;
                }

                return {
                    ...changes,
                };
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                this.handleItemSelected(changes.selectedItem);
                this.reset();

                changes.inputValue = '';
                changes.isOpen = false;

                return {
                    ...changes,
                };
            case Downshift.stateChangeTypes.blurInput:
                if (this.state.matchedBrokers.length > 0) {
                    this.handleItemSelected(
                        this.state.matchedBrokers[state.highlightedIndex]
                    );
                    this.reset();
                }

                changes.inputValue = '';
                changes.isOpen = false;

                return {
                    ...changes,
                };
            default:
                return changes;
        }
    }

    handleItemSelected(broker, keyCode, shift) {
        this.props.onBrokerSelected(broker, keyCode, shift);
    }

    handleInputKeyDown(e) {
        let isDeleting, inputValue;

        switch (e.keyCode) {
            case KEY_BACKSPACE:
                ({ isDeleting, inputValue } = this.state);

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onDelete) {
                        this.props.onDelete(DIRECTION_BACKWARD);
                    }
                }

                this.setState({ isDeleting: true });
                break;
            case KEY_DELETE:
                ({ isDeleting, inputValue } = this.state);

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onDelete) {
                        this.props.onDelete(DIRECTION_FORWARD);
                    }
                }

                this.setState({ isDeleting: true });
                break;
            case KEY_TAB:
                if (this.state.isLoadingBrokers) {
                    e.preventDefault();
                    e.stopPropagation();
                    return true;
                }

                if (!e.altKey && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();

                    if (
                        this.downshiftRef.current.items &&
                        this.downshiftRef.current.items.length > 0
                    ) {
                        const selectedItem =
                            this.downshiftRef.current.items[
                                this.downshiftRef.current.state.highlightedIndex
                            ];

                        this.handleItemSelected(
                            selectedItem,
                            e.keyCode,
                            e.shiftKey
                        );
                    } else {
                        if (!e.shiftKey) {
                            this.props.onTab();
                        } else {
                            this.props.onTabBack();
                        }
                    }
                }
                break;
            case KEY_ENTER:
                if (this.state.isLoadingBrokers) {
                    e.preventDefault();
                    e.stopPropagation();
                    return true;
                }

                if (
                    this.downshiftRef.current.items &&
                    this.downshiftRef.current.items.length === 0
                ) {
                    this.props.onEnter();
                }
                break;
            case KEY_LEFT:
                ({ isDeleting, inputValue } = this.state);

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onMoveLeft) {
                        this.props.onMoveLeft();
                    }
                }
                break;
            case KEY_RIGHT:
                ({ isDeleting, inputValue } = this.state);

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onMoveRight) {
                        this.props.onMoveRight();
                    }
                }
                break;
            default:
                if (this.isRestrictedKey(e.key)) {
                    e.preventDefault();
                    e.stopPropagation();
                }
        }
    }

    isRestrictedKey(key) {
        return (
            this.isSeparatorCharacterNotAtBeginning(key) ||
            this.isBlockedCharacter(key)
        );
    }

    isSeparatorCharacterNotAtBeginning(key) {
        const { inputValue } = this.state;
        return inputValue !== '' && this.isSeparatorCharacter(key);
    }

    isBlockedCharacter(key) {
        const result =
            key === '?' ||
            (this.props.blockedCharacters &&
                this.props.blockedCharacters.indexOf(key) > -1);
        return result;
    }

    handleInputKeyUp(e) {
        if (e.keyCode === KEY_BACKSPACE || e.keyCode === KEY_DELETE) {
            this.setState({ isDeleting: false });
        }
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
            if (!this.props.initialChar) {
                this.inputRef.current.select();
            }
        }
    }

    renderBrokers(getItemProps, highlightedIndex) {
        function getShortName(name) {
            return name && name.length > 30 ? name.substr(0, 30) + '...' : name;
        }

        return (
            <table
                className="ui compact table"
                style={{ width: '100%' }}
                border="0"
            >
                <tbody>
                    {this.state.matchedBrokers.map((item, index) => {
                        return (
                            <ThirdPartyCompanySearchResult
                                getItemProps={getItemProps}
                                highlightedIndex={highlightedIndex}
                                item={item}
                                index={index}
                            />
                        );
                    })}
                    <tr>
                        <td colSpan={3}>
                            <ThirdPartyCompanyClientCheck
                                openNewCompanyModal={
                                    this.props.openNewCompanyModal
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    hasFocus() {
        return this.inputRef.current === document.activeElement;
    }

    render() {
        const placeholder = this.props.isLastPosition
            ? 'Broker or separator'
            : '';
        const { max } = Math;

        return (
            <Downshift
                ref={this.downshiftRef}
                itemToString={this.itemToString}
                stateReducer={this.stateReducer}
                defaultHighlightedIndex={0}
                initialInputValue={this.state.inputValue}
                initialIsOpen={this.state.initialIsOpen}
            >
                {({
                    inputValue,
                    getInputProps,
                    getItemProps,
                    isOpen,
                    highlightedIndex,
                }) => (
                    <div className="downshift-container">
                        <div className={this.props.inputClass}>
                            <input
                                data-cy={GRID_CELL_INPUT}
                                style={{
                                    width: `${max(
                                        1,
                                        max(
                                            inputValue.length,
                                            placeholder.length
                                        )
                                    )}ch`,
                                }}
                                type="text"
                                {...getInputProps({
                                    tabIndex: this.props.index,
                                    ref: this.inputRef,
                                    onKeyDown: this.handleInputKeyDown,
                                    onKeyUp: this.handleInputKeyUp,
                                    className: 'downshift-input',
                                    disabled: this.props.disabled,
                                    placeholder,
                                })}
                            />
                        </div>
                        {isOpen && (
                            <div
                                className={`downshift-content ${this.props.downshiftContentClassname}`}
                                style={{
                                    ...FortDownshiftInput.getCssPositionPropInsideScrollAncestor(
                                        this.inputRef.current
                                    ),
                                    position: 'absolute',
                                    maxHeight: 'none',
                                }}
                            >
                                {this.state.error ? (
                                    <div
                                        className="downshift-menu-item"
                                        style={{ color: 'red' }}
                                    >
                                        Could not search
                                    </div>
                                ) : null}
                                {!this.state.error ? (
                                    <div
                                        className="downshift-menu"
                                        style={
                                            this.state.isLoadingBrokers
                                                ? undefined
                                                : FortDownshiftInput.getCssPositionPropInsideScrollAncestor(
                                                      this.inputRef.current
                                                  )
                                        }
                                    >
                                        {this.state.isLoadingBrokers ? (
                                            <DownshiftActivityIndicator />
                                        ) : (
                                            this.renderBrokers(
                                                getItemProps,
                                                highlightedIndex
                                            )
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                )}
            </Downshift>
        );
    }
}

ThirdPartyBrokerInput.propTypes = {
    onBrokerSelected: PropTypes.func.isRequired,
    onSeparatorEntered: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    inputClass: PropTypes.string,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    initialChar: PropTypes.string,
    openNewCompanyModal: PropTypes.func,
};

export default ThirdPartyBrokerInput;
