import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    checkbox: {
        padding: '5px',
    },
    label: {
        fontFamily: 'CeraGR',
        fontSize: '13px',
    },
}));

const FilterLabels = (props) => {
    const classes = useStyles();

    return props.filters.map((filter) => (
        <FormControlLabel
            className={classes.label}
            key={filter.key}
            control={
                <Checkbox
                    className={classes.checkbox}
                    color="primary"
                    checked={filter.checked}
                    onChange={props.handleFilterChange}
                    name={filter.key}
                />
            }
            label={filter.label}
        />
    ));
};

export default FilterLabels;
