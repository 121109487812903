import { Box, Button, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {
    doesStartWithClarksonName,
    isClarksonThirdPartyBroker,
} from '_legacy/data/isClarksonsEntity';
import { ICompany } from 'components/modal/createCompanyForm/CreateCompanyForm';
import { modalService } from 'common';

interface IBroker {
    id: number;
    type: string;
    name: string;
}

interface ThirdPartyCompanyValidationDialogProps {
    message: string;
    onCancel: () => void;
}

const ThirdPartyCompanyValidationDialog: React.FC<
    ThirdPartyCompanyValidationDialogProps
> = ({ onCancel, message }) => {
    return (
        <Box
            id="company-confirmation-modal"
            textAlign="center"
            maxWidth="500px"
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginBottom="25px"
            >
                <WarningIcon color="error" sx={{ marginRight: '5px' }} />
                <Typography variant="h5">Warning</Typography>
            </Box>
            <Box marginBottom="25px">{message}</Box>
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={onCancel} variant="contained" color="primary">
                    OK
                </Button>
            </Box>
        </Box>
    );
};

const validateSelectedThirdPartyCompany = (
    entity: any,
    company?: ICompany,
    broker?: IBroker
): boolean => {
    if (
        broker &&
        doesStartWithClarksonName(broker.name) &&
        (entity.thirdPartyCharterer?.partType === 'CustomAccount' ||
            entity.thirdPartyOwner?.partType === 'CustomAccount')
    ) {
        const message = `Saving ${broker.name} is restricted because this order/fixture has charterer/owner with non-client status. You must not transact unless you have prior approval from sanctions@clarksons.com`;
        openDialog(message);
        return false;
    } else if (
        company &&
        company.partType === 'CustomAccount' &&
        isClarksonThirdPartyBroker(entity)
    ) {
        const message = `Saving ${company.name} is restricted due to it's non-client status and this is a Clarksons managed order/fixture. You must not transact unless you have prior approval from sanctions@clarksons.com`;
        openDialog(message);
        return false;
    }
    return true;
};

const openDialog = (message: string) => {
    const content = (
        <ThirdPartyCompanyValidationDialog
            message={message}
            onCancel={() => setTimeout(modalService.close)}
        />
    );
    modalService.open({ content });
};

export default validateSelectedThirdPartyCompany;
