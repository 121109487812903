import React, { Component } from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { VesselsApi } from '_legacy/api/vesselsApi';
import FortDownshiftInput from '../../../../components/grid/inputs/FortDownshiftInput';
import { Slider } from 'react-semantic-ui-range';
import VesselDwtFormatter from '../formatters/VesselDwtFormatter';
import { getVesselPostProcessingService } from '../services/VesselPostProcessingServiceFactory';
import * as layoutActions from '_legacy/actions/layoutActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDataset } from '_legacy/models/Datasets';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

class VesselSelect extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.handleItemSelected = this.handleItemSelected.bind(this);
        this.setMultipleValues = this.setMultipleValues.bind(this);
        this.search = this.search.bind(this);

        this.state = {
            isSearching: false,
        };

        const { allowSearchVesselsByEniNumber } = getDataset(
            this.props.context.datasetId
        );
        this.vesselSearchPlaceholder = allowSearchVesselsByEniNumber
            ? 'Enter vessel name or IMO or ENI'
            : 'Enter vessel name or IMO';
    }

    itemToString = (item) => {
        return isNil(item) || isNil(item.name) ? '' : item.name;
    };

    handleItemSelected(selectedVessel, keyCode, shift) {
        if (this.props.onVesselSelected) {
            const vessel = this.convertRetrievedVessel(selectedVessel);
            const { searchVesselsWithImoOnly } = getDataset(
                this.props.context.datasetId
            );

            if (searchVesselsWithImoOnly && !vessel.imo) {
                this.props.onVesselSelected(null, keyCode, shift);
                return;
            }

            return this.doVesselPostProcessing(vessel, keyCode, shift);
        }
    }

    handlePlaceholderEntered = (placeholder, keyCode, shift) => {
        const vessel = this.createTbnVessel();

        return this.doVesselPostProcessing(vessel, keyCode, shift);
    };

    doVesselPostProcessing = (vessel, keyCode, shift) => {
        const service = getVesselPostProcessingService(
            this.props.context.datasetId
        );

        if (service) {
            vessel.shouldUpdateOwner = true;

            if (service.shouldShowOwnerConfirmationWindow(this.props, vessel)) {
                service.showOwnerConfirmationDialog(
                    this.props,
                    vessel,
                    keyCode,
                    shift
                );
                return;
            }
        }

        this.props.onVesselSelected(vessel, keyCode, shift);
    };

    convertRetrievedVessel(vessel) {
        return {
            vesselId: vessel.vesselId,
            imo: vessel.imo,
            eniNumber: vessel.eniNumber,
            shipsBaseTankerVesselId: vessel.shipsBaseTankerVesselId,
            name: vessel.name.toUpperCase(),
            scrubber: vessel.scrubber,
            yard: vessel.yard,
            dwt: vessel.dwt,
            yob: vessel.yob,
            design: vessel.design,
            imoClass: vessel.imoClass
                ? {
                      imoGradeI: vessel.imoClass.imoGradeI,
                      imoGradeII: vessel.imoClass.imoGradeII,
                      imoGradeIII: vessel.imoClass.imoGradeIII,
                  }
                : null,
            iceClassNotation: vessel.iceClassNotation,
            grades: vessel.grades,
            nitrogenPlant: vessel.nitrogenPlant,
            bestOperator: vessel.bestOperator,
            gainCompany: vessel.gainCompany
                ? {
                      value: vessel.gainCompany.value,
                      partType: vessel.gainCompany.partType,
                      name: vessel.gainCompany.name,
                  }
                : null,
            fleetTypeIds: vessel.fleetTypeIds,
            isTbn: false,
        };
    }

    createCustomVessel(value) {
        return {
            vesselId: null,
            imo: null,
            eniNumber: null,
            shipsBaseTankerVesselId: null,
            dwt: null,
            scrubber: null,
            yard: null,
            design: null,
            imoClass: null,
            iceClassNotation: null,
            grades: null,
            nitrogenPlant: null,
            name: value.toUpperCase(),
            yob: null,
            isTbn: false,
        };
    }

    createTbnVessel() {
        return {
            vesselId: null,
            imo: null,
            eniNumber: null,
            shipsBaseTankerVesselId: null,
            name: 'TBN',
            scrubber: null,
            yard: null,
            design: null,
            imoClass: null,
            iceClassNotation: null,
            grades: null,
            nitrogenPlant: null,
            dwt: null,
            yob: null,
            isTbn: true,
        };
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    renderVessel(
        getItemProps,
        highlightedIndex,
        vessel,
        index,
        length,
        disalowFreeTextVessel,
        allowSearchVesselsByEniNumber
    ) {
        const imoEniDisplayValue = vessel.imo
            ? vessel.imo
            : allowSearchVesselsByEniNumber && vessel.eniNumber
            ? `${vessel.eniNumber}*`
            : '-';

        return disalowFreeTextVessel && length === 1 && vessel.key ? (
            <tr key={vessel.key}>
                <td
                    colSpan={4}
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                >
                    <span>Unable to find any matching Vessel</span>
                </td>
            </tr>
        ) : (
            ((disalowFreeTextVessel && !vessel.key) ||
                !disalowFreeTextVessel) && (
                <tr
                    {...getItemProps({
                        item: vessel,
                        key: vessel.vesselId || vessel.key,
                        style: {
                            backgroundColor:
                                highlightedIndex === index
                                    ? 'rgba(0,0,0,0.05)'
                                    : 'white',
                        },
                    })}
                >
                    <td>
                        {vessel.key && !disalowFreeTextVessel ? (
                            <span>ADD: "{vessel.name}"</span>
                        ) : (
                            <span>{imoEniDisplayValue}</span>
                        )}
                    </td>
                    <td> {!vessel.key && <span>{vessel.name}</span>}</td>
                    <td>
                        {vessel.dwt && (
                            <span>
                                {VesselDwtFormatter({ value: vessel.dwt })}
                            </span>
                        )}
                    </td>
                    <td> {vessel.yob && <span>{vessel.yob}</span>}</td>
                </tr>
            )
        );
    }

    renderVesselHeader(allowSearchVesselsByEniNumber) {
        const header = allowSearchVesselsByEniNumber ? 'IMO/ENI*' : 'IMO';

        return (
            <thead>
                <tr>
                    {allowSearchVesselsByEniNumber ? (
                        <Tooltip
                            placement="top"
                            overlay="Barge's ENI are denoted by an * in the column below"
                        >
                            <th>{header}</th>
                        </Tooltip>
                    ) : (
                        <th>{header}</th>
                    )}
                    <th>Name</th>
                    <th>DWT</th>
                    <th>YOB</th>
                </tr>
            </thead>
        );
    }

    updateFixtureLayout(values) {
        const currentOptions = {
            ...this.props.fixturesOptions,
            vesselOptions: { minDWT: values[0], maxDWT: values[1] },
        };
        this.props.actions.onFixturesGridOptionsChanged(currentOptions);
    }

    setMultipleValues(values) {
        this.updateFixtureLayout(values);
        this.inputRef.current.onFilterChanged();
    }

    search(query, cancelToken) {
        const { searchVesselsWithImoOnly } = getDataset(
            this.props.context.datasetId
        );
        this.setState({ isSearching: true });

        return VesselsApi.searchVessel(
            query,
            this.props.context.datasetId,
            this.props.minDWT * 1000,
            this.props.maxDWT * 1000,
            searchVesselsWithImoOnly,
            cancelToken
        );
    }

    render() {
        const sizeOfFilter = 77;
        const settings = {
            start: [this.props.minDWT, this.props.maxDWT],
            min: 0,
            max: 750,
            step: 10,
            onChange: (value) => {
                this.setMultipleValues(value);
            },
        };
        const { searchVesselsWithImoOnly, allowSearchVesselsByEniNumber } =
            getDataset(this.props.context.datasetId);

        const filter = (
            <div className="downshift-filter">
                <table style={{ width: '100%', tableLlayout: 'fixed' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '50%' }}>
                                <label className="ui label">
                                    {this.props.minDWT * 1000}
                                </label>
                            </td>
                            <td style={{ width: '50%', textAlign: 'right' }}>
                                <label className="ui label">
                                    {this.props.maxDWT * 1000}
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ marginTop: '10px' }}>
                    <Slider multiple={true} color="blue" settings={settings} />
                </div>
            </div>
        );

        return (
            <FortDownshiftInput
                {...this.props}
                className="vesselSelect"
                ref={this.inputRef}
                itemToString={this.itemToString}
                searchFunc={this.search}
                renderTableHeader={() =>
                    this.renderVesselHeader(allowSearchVesselsByEniNumber)
                }
                renderItem={(...params) =>
                    this.renderVessel(
                        ...params,
                        searchVesselsWithImoOnly,
                        allowSearchVesselsByEniNumber
                    )
                }
                placeholder={this.vesselSearchPlaceholder}
                createCustomItem={this.createCustomVessel}
                onItemSelected={this.handleItemSelected}
                placeholderTerms={['TBN']}
                onPlaceholderEntered={this.handlePlaceholderEntered}
                sizeProps={{ additionalComponentSize: sizeOfFilter }}
            >
                {this.state.isSearching && filter}
            </FortDownshiftInput>
        );
    }
}

VesselSelect.propTypes = {
    onVesselSelected: PropTypes.func.isRequired,
    shouldSelectItemOnTab: PropTypes.bool.isRequired,
    inputClass: PropTypes.string,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    initialChar: PropTypes.string,
    onInputCleared: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const fixturesOptions =
        state.layouts.selectedLayout.fixtures.currentOptions;

    const props = {
        fixturesOptions: fixturesOptions,
        minDWT: fixturesOptions.vesselOptions.minDWT,
        maxDWT: fixturesOptions.vesselOptions.maxDWT,
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...layoutActions }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(VesselSelect);
