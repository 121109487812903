import { Box, Button, Typography, styled } from '@mui/material';
import { directoryClientCheckUrl } from '_legacy/config';

interface IThirdPartyCompanyClientCheckProps {
    openNewCompanyModal: () => void;
}

const ThirdPartyCompanyClientCheck: React.FC<
    IThirdPartyCompanyClientCheckProps
> = ({ openNewCompanyModal }) => {
    return (
        <Box display="flex" flexDirection="column">
            <StyledHeader>
                Not found? To add a new company, select KYC level:
            </StyledHeader>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Box display="flex" flexDirection="column">
                    <StyledKycButton
                        variant="contained"
                        href={directoryClientCheckUrl}
                        target="_blank"
                        rel="noreferrer"
                        fullWidth
                    >
                        KYC REQUIRED
                    </StyledKycButton>
                    <StyledButtonTooltip>
                        Proceed to Client Check to start the KYC process.
                    </StyledButtonTooltip>
                </Box>
                <Box display="flex" flexDirection="column">
                    <StyledNonKycButton
                        variant="outlined"
                        onClick={openNewCompanyModal}
                        fullWidth
                    >
                        NO KYC REQUIRED
                    </StyledNonKycButton>
                    <StyledButtonTooltip>
                        Create a company for market data capture ONLY.
                    </StyledButtonTooltip>
                </Box>
            </Box>
        </Box>
    );
};

const StyledHeader = styled(Typography)({
    color: 'black',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 600,
    paddingTop: '5px',
    paddingBottom: '12px',
});

const StyledKycButton = styled(Button)({
    backgroundColor: 'var(--action) !important',
    minWidth: '250px',
    '&:hover': {
        color: 'white',
    },
}) as typeof Button;

const StyledNonKycButton = styled(Button)({
    color: 'var(--action) !important',
    minWidth: '250px',
});

const StyledButtonTooltip = styled(Typography)({
    color: 'black',
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontWeight: 400,
    paddingTop: '5px',
});

export default ThirdPartyCompanyClientCheck;
