const isClarksonsEntity = (entity) =>
    entity && (isClarksonBroker(entity) || isClarksonThirdPartyBroker(entity));

const isClarksonBroker = (entity) =>
    entity.brokers &&
    entity.brokers.filter((b) => b.name.indexOf('CLARKSONS') >= 0).length > 0;

export const isClarksonThirdPartyBroker = (entity) =>
    entity.thirdPartyBrokers &&
    entity.thirdPartyBrokers.filter((b) => doesStartWithClarksonName(b.name))
        .length > 0;

export const doesStartWithClarksonName = (name) => {
    if (!name) return false;

    const clarksonNames = ['CLARKSON', 'GIBB', 'HC SHIPPING', 'H CLARKSON'];

    return clarksonNames.filter((cn) => name.startsWith(cn)).length > 0;
};

export default isClarksonsEntity;
