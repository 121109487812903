import React from 'react';
import FilterLabels from './FilterLabels';
import WithFilters from './HOCs/WithFilters';

import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';

const useStyles = makeStyles(() => ({
    formLabel: {
        marginBottom: '5px',
        color: '#0000008A !important',
        fontFamily: 'CeraGR',
        fontSize: '13px',
    },
    formGroup: {
        marginLeft: '12px',
    },
}));

const SavedFiltersForm = ({
    savedFilters,
    setSavedFilters,
    handleFilterChange,
}) => {
    const classes = useStyles();

    const handleSavedFilterChange = (event) =>
        handleFilterChange(event, savedFilters, setSavedFilters);

    return savedFilters.length > 0 ? (
        <>
            {' '}
            <FormLabel component="legend" className={classes.formLabel}>
                SAVED FILTERS
            </FormLabel>
            <FormGroup className={classes.formGroup}>
                <FilterLabels
                    filters={savedFilters}
                    handleFilterChange={handleSavedFilterChange}
                />
            </FormGroup>{' '}
        </>
    ) : null;
};

export default WithFilters(SavedFiltersForm);
