import { VOY } from '_legacy/components/toolPanels/commonSettings/OrderOrFixtureTypes';
import { SHORT_HAND_QUANTITY_FORMAT } from '_legacy/modules/columns/quantity/services/QuatityServiceFactory';
import { PANAMAX_DIRECTION_LOGIC } from '_legacy/services/directions/DirectionServiceFactory';
import { SavedLayoutFragment } from 'api/fragments';

export type SavedLayoutModel = SavedLayoutFragment & { isTemplate: boolean };

export interface LayoutGridColumn {
    colId: string;
    aggFunc: null;
    flex: null;
    hide: boolean;
    pinned: 'left' | 'right' | null;
    pivot: boolean;
    pivotIndex: null;
    rowGroup: boolean;
    rowGroupIndex: null;
    sort: null;
    sortIndex: null;
    width: number;
}

export interface BaseGridConfig {
    filterOptions: {};
    columnOptions: LayoutGridColumn[];
    rowGroups: { colId: string; checked: boolean }[];
    rowGroupsSorting: { colId: string; sorting: string[] }[];
}

export interface LayoutCommonSettings {
    directionLogic: { initialState: string; currentState: string };
    quantityFormat: { initialState: string; currentState: string };
    defaultType: { initialState: string; currentState: string };
}

export interface LayoutGridConfig<T extends BaseGridConfig> {
    initialOptions: T;
    currentOptions: T;
    wereOptionsChanged: boolean;
    collapsedRowGroups: any[];
}

export type LayoutGridType = 'fixtures' | 'orders';

export type FixturesGridConfig = BaseGridConfig & {
    shouldOnlyDisplayRumouredFixtures: boolean;
    vesselOptions: { minDWT: number; maxDWT: number };
};
export type OrdersGridConfig = BaseGridConfig & {
    shouldHighlightNewOrders: boolean;
    shouldShowConvertedOrders: boolean;
    shouldOnlyDisplayRumouredOrders: boolean;
};

export interface SelectedLayoutModel {
    selectedLayoutId: string | null;
    name: string;
    isTemplate: boolean;
    areThereAnyUnsavedLayoutChanges: boolean;
    commonSettings: LayoutCommonSettings;
    fixtures: LayoutGridConfig<FixturesGridConfig>;
    orders: LayoutGridConfig<OrdersGridConfig>;
}

export interface LayoutsState {
    loading: boolean;
    selectedDatasetId: number | null;
    allLayouts: SavedLayoutModel[];
    selectedLayout: SelectedLayoutModel;
}

export interface BaseLayoutChange {
    gridType: LayoutGridType;
}

export interface LayoutCollapsedRowGroupsChange extends BaseLayoutChange {
    collapsedRowGroups: any[];
}

const defaultFixturesOptions: FixturesGridConfig = {
    filterOptions: {},
    columnOptions: [],
    vesselOptions: {
        minDWT: 0,
        maxDWT: 750,
    },
    rowGroups: [],
    rowGroupsSorting: [],
    shouldOnlyDisplayRumouredFixtures: false,
};
const defaultOrdersOptions: OrdersGridConfig = {
    filterOptions: {},
    columnOptions: [],
    shouldHighlightNewOrders: false,
    shouldShowConvertedOrders: false,
    shouldOnlyDisplayRumouredOrders: false,
    rowGroups: [],
    rowGroupsSorting: [],
};

const defaultCommonSettings: LayoutCommonSettings = {
    directionLogic: {
        initialState: PANAMAX_DIRECTION_LOGIC,
        currentState: PANAMAX_DIRECTION_LOGIC,
    },
    quantityFormat: {
        initialState: SHORT_HAND_QUANTITY_FORMAT,
        currentState: SHORT_HAND_QUANTITY_FORMAT,
    },
    defaultType: {
        initialState: VOY,
        currentState: VOY,
    },
};

export const layoutsInitialState: LayoutsState = {
    loading: false,
    selectedDatasetId: null,
    allLayouts: [],
    selectedLayout: {
        selectedLayoutId: null,
        name: '',
        isTemplate: false,
        areThereAnyUnsavedLayoutChanges: false,
        commonSettings: defaultCommonSettings,
        fixtures: {
            initialOptions: defaultFixturesOptions,
            currentOptions: defaultFixturesOptions,
            wereOptionsChanged: false,
            collapsedRowGroups: [],
        },
        orders: {
            initialOptions: defaultOrdersOptions,
            currentOptions: defaultOrdersOptions,
            wereOptionsChanged: false,
            collapsedRowGroups: [],
        },
    },
};
