import LayoutIcons from 'components/common/LayoutIcons';
import { SavedLayoutModel } from 'store/feature/layoutsSlice/models';
import { useMemo } from 'react';
import { StringFormatter } from 'utils';

function useLayoutTitle(
    layout: Pick<SavedLayoutModel, 'name' | 'isTemplate'>
): { icon: React.ReactNode; text: React.ReactNode; name: string } {
    return useMemo(() => {
        const name = StringFormatter.trimTitle(layout.name);
        const Icon = layout.isTemplate
            ? LayoutIcons.Template
            : LayoutIcons.User;
        return { icon: <Icon />, text: <>{name}</>, name };
    }, [layout.name, layout.isTemplate]);
}

export default useLayoutTitle;
