import client from './graphQlClient';
import {
    GET_GAIN_ACCOUNTS_BY_IDS,
    GET_GAIN_ACCOUNT_GROUPS_BY_IDS,
    SEARCH_THIRD_PARTY_COMPANIES,
    CREATE_CUSTOM_COMPANY,
    SEARCH_THIRD_PARTY_COMPANY_PARENTS,
} from './queries/ThirdPartyCompanyQueries';

export default class ThirdPartyCompanyApi {
    static search = (search, cancelToken) => {
        return new Promise((resolve, reject) => {
            client()
                .query({
                    query: SEARCH_THIRD_PARTY_COMPANIES,
                    fetchPolicy: 'no-cache',
                    variables: {
                        search,
                    },
                })
                .then((result) => {
                    resolve(result.data.thirdPartyCompanies);
                });

            cancelToken.promise.then((reason) => {
                reject(reason);
            });
        });
    };

    static searchParents = (search, cancelToken) => {
        return new Promise((resolve, reject) => {
            client()
                .query({
                    query: SEARCH_THIRD_PARTY_COMPANY_PARENTS,
                    fetchPolicy: 'no-cache',
                    variables: {
                        search,
                    },
                })
                .then((result) => {
                    resolve(result.data.thirdPartyCompanyParents);
                });

            cancelToken.promise.then((reason) => {
                reject(reason);
            });
        });
    };

    static getAccountsByIds = (ids) =>
        client()
            .query({
                query: GET_GAIN_ACCOUNTS_BY_IDS,
                fetchPolicy: 'no-cache',
                variables: {
                    ids,
                },
            })
            .then((result) => result.data.gainAccountsByIds);

    static getAccountGroupsByIds = (ids) =>
        client()
            .query({
                query: GET_GAIN_ACCOUNT_GROUPS_BY_IDS,
                fetchPolicy: 'no-cache',
                variables: {
                    ids,
                },
            })
            .then((result) => result.data.gainAccountGroupsByIds);

    static createCustomCompany = (createCustomCompanyModel) =>
        client()
            .query({
                query: CREATE_CUSTOM_COMPANY,
                fetchPolicy: 'no-cache',
                variables: {
                    createCustomCompanyModel,
                },
            })
            .then((result) => result.data.createCustomCompany);
}
