import { useState } from 'react';
import CreateLayoutDialog from './CreateLayoutDialog';
import SwitchLayoutDialog from './SwitchLayoutDialog';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { layoutsSelectors, selectLayout } from 'store/feature/layoutsSlice';

function useSwitchLayout(): {
    switchLayout: (id: string) => void;
    saveAsLayout: () => void;
    switchLayoutDialog: React.ReactNode;
} {
    const dispatch = useAppDispatch();
    const selectedLayout = useAppSelector(layoutsSelectors.selectedLayout);
    const [state, setState] = useState<{
        id: string;
        showDialog: 'switch' | 'saveAs' | false;
    }>({ id: '', showDialog: false });

    const closeSwitchDialog = () => setState({ id: '', showDialog: false });
    const switchLayoutSilently = (id: string) => {
        dispatch(selectLayout({ id }));
    };

    const switchLayout = (id: string) => {
        if (selectedLayout.areThereAnyUnsavedLayoutChanges) {
            setState({ id, showDialog: 'switch' });
        } else {
            switchLayoutSilently(id);
        }
    };

    const saveAsLayout = () => setState({ id: state.id, showDialog: 'saveAs' });

    const switchLayoutDialog = (
        <>
            <SwitchLayoutDialog
                open={state.showDialog === 'switch'}
                onCancel={closeSwitchDialog}
                onContinue={() => {
                    switchLayoutSilently(state.id);
                    closeSwitchDialog();
                }}
                onSaveAs={saveAsLayout}
            />
            <CreateLayoutDialog
                open={state.showDialog === 'saveAs'}
                onClose={closeSwitchDialog}
            />
        </>
    );

    return { switchLayout, saveAsLayout, switchLayoutDialog };
}

export default useSwitchLayout;
