import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import MultipleThirdPartyBrokerSelect from '../inputs/MultipleThirdPartyBrokerSelect';
import {
    normalizeParts,
    trimTrailingSeparators,
    SEPARATOR_PLUS,
} from '../../../models/common/EntityPart';
import { createSeparatorBrokerPart } from '../../../models/ThirdPartyBrokerPart';

const ThirdPartyBrokerEditor = forwardRef((props, ref) => {
    const selectRef = useRef(null);

    const focus = () => {
        selectRef.current.focus();
    };

    const handleOnTab = () => props.api.tabToNextCell();

    const handleOnTabBack = () => props.api.tabToPreviousCell();

    const handleOnEnter = () => props.stopEditing();

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                if (selectRef.current.formattedValue.length > 0) {
                    var parts = normalizeParts(
                        selectRef.current.formattedValue,
                        createSeparatorBrokerPart,
                        SEPARATOR_PLUS
                    );
                    return trimTrailingSeparators(parts);
                } else {
                    return null;
                }
            },

            isPopup() {
                return true;
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    return (
        <MultipleThirdPartyBrokerSelect
            className="location-editor"
            ref={selectRef}
            context={props.context}
            field={props.column.colDef.field}
            initialChar={props.charPress}
            value={props.value}
            onTab={handleOnTab}
            onTabBack={handleOnTabBack}
            onEnter={handleOnEnter}
            seperatorCharacters={[SEPARATOR_PLUS]}
            blockedCharacters={props.blockedCharacters}
            defaultSeperatorCharacter={SEPARATOR_PLUS}
            entity={props?.data}
        />
    );
});

export default ThirdPartyBrokerEditor;
