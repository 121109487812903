import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GroupingToolPanel from './GroupingToolPanel';
import * as layoutActions from '../../../actions/layoutActions';
import { getDefaultFixturesRowGroupsSorting } from '_legacy/services/RowGroupsSortingService';
import { FIXTURE_GRID_TYPE } from '_legacy/constants/gridTypes';

export const GroupingFixturesToolPanel = (props) => {
    const fixturesGridProps = {
        ...props,
        onGridOptionsChanged: props.actions.onFixturesGridOptionsChanged,
        currentOptions: props.currentOptions,
        getDefaultRowGroupsSorting: getDefaultFixturesRowGroupsSorting,
        gridType: FIXTURE_GRID_TYPE,
    };
    return <GroupingToolPanel {...fixturesGridProps} />;
};

const mapStateToProps = (state) => {
    const props = {
        currentOptions: state.layouts.selectedLayout.fixtures.currentOptions,
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...layoutActions }, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupingFixturesToolPanel);
