import _ from 'lodash';
import { createAppSlice } from 'store/utils';
import { refreshUserInfo } from '../userSlice';
import { fetchSelectedLayoutId } from '_legacy/localStorage';
import {
    LayoutCollapsedRowGroupsChange,
    LayoutGridType,
    layoutsInitialState,
    LayoutsState,
    SavedLayoutModel,
} from './models';
import legacyLayoutReducer from '_legacy/reducers/layoutReducer';
import * as legacyActionTypes from '_legacy/actions/actionTypes';
import {
    normalizeLayout,
    resolveCurrentLayout,
    serializeLayoutData,
} from './utils';
import { LayoutsApi } from 'api';

const layoutsSlice = createAppSlice({
    name: 'layouts',
    initialState: (): LayoutsState =>
        _.merge({}, layoutsInitialState, {
            selectedLayout: { selectedLayoutId: fetchSelectedLayoutId() },
        }),
    reducers: (create) => ({
        selectLayout: create.reducer<{ id: string }>((state, { payload }) => {
            const selected = state.allLayouts.find((x) => x.id === payload.id);
            if (selected) {
                state.selectedLayout = normalizeLayout(
                    selected,
                    selected.datasetId
                );
            }
        }),
        revertLayout: create.reducer((state) => {
            const layout = state.selectedLayout;
            layout.areThereAnyUnsavedLayoutChanges = false;
            layout.commonSettings.defaultType.currentState =
                layout.commonSettings.defaultType.initialState;
            layout.commonSettings.directionLogic.currentState =
                layout.commonSettings.directionLogic.initialState;
            layout.commonSettings.quantityFormat.currentState =
                layout.commonSettings.quantityFormat.initialState;
            layout.fixtures.currentOptions = _.cloneDeep(
                layout.fixtures.initialOptions
            );
            layout.fixtures.wereOptionsChanged = false;
            layout.orders.currentOptions = _.cloneDeep(
                layout.orders.initialOptions
            );
            layout.orders.wereOptionsChanged = false;
        }),
        changeCollapsedRowGroups:
            create.reducer<LayoutCollapsedRowGroupsChange>(
                (state, { payload: { gridType, collapsedRowGroups } }) => {
                    const layout = state.selectedLayout;
                    if (
                        gridType === 'fixtures' &&
                        !_.isEqual(
                            layout.fixtures.collapsedRowGroups,
                            collapsedRowGroups
                        )
                    ) {
                        layout.fixtures.collapsedRowGroups = collapsedRowGroups;
                        layout.fixtures.wereOptionsChanged = true;
                        layout.areThereAnyUnsavedLayoutChanges = true;
                    } else if (
                        gridType === 'orders' &&
                        !_.isEqual(
                            layout.orders.collapsedRowGroups,
                            collapsedRowGroups
                        )
                    ) {
                        layout.orders.collapsedRowGroups = collapsedRowGroups;
                        layout.orders.wereOptionsChanged = true;
                        layout.areThereAnyUnsavedLayoutChanges = true;
                    }
                }
            ),
        createUserLayout: create.asyncThunk(
            async (
                { name, gridType }: { name: string; gridType: LayoutGridType },
                { getState, rejectWithValue }
            ) => {
                const { layouts: state } = getState() as {
                    layouts: LayoutsState;
                };
                const selectedLayoutId = state.selectedLayout.selectedLayoutId;
                const datasetId = state.selectedDatasetId;

                if (!datasetId || !selectedLayoutId) {
                    return rejectWithValue('Cannot create layout');
                }

                const data = serializeLayoutData(state.selectedLayout);

                const result = await LayoutsApi.createUserLayout({
                    ...data,
                    datasetId,
                    name,
                });

                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    const newLayout: SavedLayoutModel = {
                        ...result,
                        isTemplate: false,
                    };
                    state.allLayouts.push(newLayout);
                    state.selectedLayout = normalizeLayout(
                        newLayout,
                        newLayout.datasetId
                    );
                    state.loading = false;
                },
            }
        ),
        updateUserLayout: create.asyncThunk(
            async (
                { name, gridType }: { name?: string; gridType: LayoutGridType },
                { getState, rejectWithValue }
            ) => {
                const { layouts: state } = getState() as {
                    layouts: LayoutsState;
                };
                const selectedLayoutId = state.selectedLayout.selectedLayoutId;
                const datasetId = state.selectedDatasetId;

                if (!datasetId || !selectedLayoutId) {
                    return rejectWithValue('Cannot create layout');
                }
                name ??= state.selectedLayout.name;

                const data = serializeLayoutData(state.selectedLayout);

                const result = await LayoutsApi.updateUserLayout({
                    ...data,
                    id: selectedLayoutId,
                    name,
                });

                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    const newLayout: SavedLayoutModel = {
                        ...result,
                        isTemplate: false,
                    };
                    const idx = state.allLayouts.findIndex(
                        (x) => x.id === newLayout.id
                    );
                    if (idx > -1) {
                        state.allLayouts[idx] = newLayout;
                    }
                    state.selectedLayout = normalizeLayout(
                        newLayout,
                        newLayout.datasetId
                    );
                    state.loading = false;
                },
            }
        ),
        renameUserLayout: create.asyncThunk(
            async ({
                id,
                name,
                gridType,
            }: {
                id: string;
                name: string;
                gridType: LayoutGridType;
            }) => {
                const result = await LayoutsApi.renameUserLayout(id, name);
                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    const idx = state.allLayouts.findIndex(
                        (x) => x.id === result.id
                    );
                    if (idx > -1) {
                        state.allLayouts[idx].name = result.name;
                    }
                    state.loading = false;
                },
            }
        ),
        setUserLayoutAsPreferred: create.asyncThunk(
            async ({
                id,
                gridType,
            }: {
                id: string;
                gridType: LayoutGridType;
            }) => {
                const result = await LayoutsApi.setUserLayoutAsPreferred(id);
                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    state.allLayouts.forEach((x) => {
                        x.isPreferred = x.id === result.id;
                    });
                    state.loading = false;
                },
            }
        ),
        deleteUserLayout: create.asyncThunk(
            async ({
                id,
                gridType,
            }: {
                id: string;
                gridType: LayoutGridType;
            }) => {
                const result = await LayoutsApi.deleteUserLayout(id);
                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    const idx = state.allLayouts.findIndex(
                        (x) => x.id === result.id
                    );
                    if (idx > -1) {
                        state.allLayouts.splice(idx, 1);
                        if (
                            state.selectedLayout.selectedLayoutId === result.id
                        ) {
                            var current = resolveCurrentLayout(state);
                            if (current) {
                                state.selectedLayout = normalizeLayout(
                                    current,
                                    state.selectedDatasetId
                                );
                            }
                        }
                    }
                    state.loading = false;
                },
            }
        ),
        createTemplateLayout: create.asyncThunk(
            async (
                { name, gridType }: { name: string; gridType: LayoutGridType },
                { getState, rejectWithValue }
            ) => {
                const { layouts: state } = getState() as {
                    layouts: LayoutsState;
                };
                const selectedLayoutId = state.selectedLayout.selectedLayoutId;
                const datasetId = state.selectedDatasetId;

                if (!selectedLayoutId || !datasetId) {
                    return rejectWithValue({
                        message: 'Cannot create layout',
                    });
                }

                const data = serializeLayoutData(state.selectedLayout);

                const result = await LayoutsApi.createSharedLayout({
                    ...data,
                    datasetId,
                    name,
                });

                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    const newLayout: SavedLayoutModel = {
                        ...result,
                        isTemplate: true,
                    };
                    state.allLayouts.push(newLayout);
                    state.selectedLayout = normalizeLayout(
                        newLayout,
                        newLayout.datasetId
                    );
                    state.loading = false;
                },
            }
        ),
        updateTemplateLayout: create.asyncThunk(
            async (
                { name, gridType }: { name?: string; gridType: LayoutGridType },
                { getState, rejectWithValue }
            ) => {
                const { layouts: state } = getState() as {
                    layouts: LayoutsState;
                };
                const model = state.allLayouts.find(
                    (x) =>
                        x.id === state.selectedLayout.selectedLayoutId &&
                        x.isTemplate
                );
                const datasetId = state.selectedDatasetId;

                if (!datasetId || !model) {
                    return rejectWithValue({ message: 'Cannot update layout' });
                }
                name ??= state.selectedLayout.name;

                const data = serializeLayoutData(state.selectedLayout);

                const result = await LayoutsApi.updateSharedLayout({
                    ...data,
                    id: model.id,
                    name,
                });

                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    const newLayout: SavedLayoutModel = {
                        ...result,
                        isTemplate: true,
                    };
                    const idx = state.allLayouts.findIndex(
                        (x) => x.id === newLayout.id
                    );
                    if (idx > -1) {
                        state.allLayouts[idx] = newLayout;
                    }
                    state.selectedLayout = normalizeLayout(
                        newLayout,
                        newLayout.datasetId
                    );
                    state.loading = false;
                },
            }
        ),
        renameTemplateLayout: create.asyncThunk(
            async ({
                id,
                name,
                gridType,
            }: {
                id: string;
                name: string;
                gridType: LayoutGridType;
            }) => {
                const result = await LayoutsApi.renameSharedLayout(id, name);
                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    const idx = state.allLayouts.findIndex(
                        (x) => x.id === result.id
                    );
                    if (idx > -1) {
                        state.allLayouts[idx].name = result.name;
                    }
                    state.loading = false;
                },
            }
        ),
        deleteTemplateLayout: create.asyncThunk(
            async ({
                id,
                gridType,
            }: {
                id: string;
                gridType: LayoutGridType;
            }) => {
                const result = await LayoutsApi.deleteSharedLayout(id);
                return { result, gridType };
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload: { result } }) => {
                    const idx = state.allLayouts.findIndex(
                        (x) => x.id === result.id
                    );
                    if (idx > -1) {
                        state.allLayouts.splice(idx, 1);
                        if (
                            state.selectedLayout.selectedLayoutId === result.id
                        ) {
                            var current = resolveCurrentLayout(state);
                            if (current) {
                                state.selectedLayout = normalizeLayout(
                                    current,
                                    state.selectedDatasetId
                                );
                            }
                        }
                    }
                    state.loading = false;
                },
            }
        ),
    }),
    extraReducers: (builder) =>
        builder
            .addCase(refreshUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(refreshUserInfo.rejected, (state) => {
                state.loading = false;
            })
            .addCase(
                refreshUserInfo.fulfilled,
                (state, { payload: { userLayouts, sharedLayouts } }) => {
                    state.allLayouts = [];
                    userLayouts.forEach((x) =>
                        state.allLayouts.push({
                            ...x,
                            isTemplate: false,
                        })
                    );
                    sharedLayouts.forEach((x) =>
                        state.allLayouts.push({
                            ...x,
                            isTemplate: true,
                            isPreferred: false,
                        })
                    );

                    state.loading = false;
                }
            )
            .addCase(legacyActionTypes.USER_GROUP_SET, (state, action: any) => {
                const datasetId = action?.group?.datasetId;
                state.selectedDatasetId = datasetId ?? null;

                var current = resolveCurrentLayout(state);
                if (current) {
                    state.selectedLayout = normalizeLayout(current, datasetId);
                }
            })
            .addDefaultCase(
                (state, action) =>
                    legacyLayoutReducer(state as any, action) as any
            ),
    selectors: {
        loading: (state) => state.loading,
        selectedLayout: (state) => state.selectedLayout,
        selectedLayoutId: (state) => state.selectedLayout.selectedLayoutId,
        selectLayout: (state) => state.selectedLayout,
        allLayouts: (state) => state.allLayouts,
        selectedDatasetId: (state) => state.selectedDatasetId,
        selectedDatasetLayouts: (state) => {
            const items = !state.selectedDatasetId
                ? state.allLayouts
                : state.allLayouts.filter(
                      (x) =>
                          !x.datasetId ||
                          x.datasetId < 1 ||
                          x.datasetId === state.selectedDatasetId
                  );
            return _.orderBy(items, ['isTemplate', 'name'], ['asc', 'asc']);
        },
        hasPendingChanges: (state) =>
            state.selectedLayout.areThereAnyUnsavedLayoutChanges,
    },
});

export const layoutsSelectors = layoutsSlice.selectors;

export const {
    selectLayout,
    revertLayout,
    changeCollapsedRowGroups,
    createUserLayout,
    updateUserLayout,
    renameUserLayout,
    setUserLayoutAsPreferred,
    deleteUserLayout,
    createTemplateLayout,
    updateTemplateLayout,
    renameTemplateLayout,
    deleteTemplateLayout,
} = layoutsSlice.actions;

export default layoutsSlice.reducer;
