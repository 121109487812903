import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import * as userActions from '../../actions/userActions';
import * as layoutActions from '../../actions/layoutActions';
import BasicGrid, {
    BasicGridWithoutRouter,
    ACTION_CONVERT_TO_FIXTURE,
    ACTION_CLONE_ORDER,
    ACTION_OPEN_AUDIT,
    ACTION_REINSTATE,
    ACTION_MAKE_NEW,
    ACTION_MAKE_ACTIVE,
    ACTION_WITHDRAWN,
} from '../grid/BasicGrid';
import { bindActionCreators } from 'redux';
import createGridCellValueSetter from '../grid/setters/createGridCellValueSetter';
import { currentGridCreationIndex } from '../../reducers/userSelectors';
import Activity, { ActivityTabs } from '../ui/Activity';
import { CSSTransition } from 'react-transition-group';
import {
    DELETE_ORDER_MUTATION,
    REINSTATE_ORDER_MUTATION,
    CONVERT_ORDER_MUTATION,
    createOrderFieldMutation,
    MAKE_ORDER_PUBLIC_MUTATION,
    MAKE_ORDER_PRIVATE_MUTATION,
    SHARE_ORDER_MUTATION,
    SHARE_ORDER_TO_GROUP_MUTATION,
    UPDATE_ORDER_PRIVATE_FIELDS_MUTATION,
} from '../../api/queries/OrderQueries';
import AddOrder from './AddOrder';
import StripedLoader from '../common/StripedLoader';
import graphQlClient from '../../api/graphQlClient';
import {
    ORDER_ADDED_SUBSCRIPTION,
    ORDER_UPDATED_SUBSCRIPTION,
    ORDER_SHARED_SUBSCRIPTION,
} from '../../api/subscriptions/orderSubscriptions';
import { ORDER_GRID_TYPE } from '../../constants/gridTypes';
import { commonRowAndCellClassRules } from '../grid/classRules/classRules';
import logger from '../../diagnostics/logging/logger';
import defaultGroupSortComparator from '../grid/comparators/DefaultGroupSortComparator';

import {
    fetchShowDeletedFixtures,
    fetchShowDeletedOrders,
    saveShowDeletedOrders,
} from '../../localStorage';

import Toolbar from '../toolbar/Toolbar';
import MoreButton from '../toolbar/MoreButton';
import DataButton from '../toolbar/DataButton';
import ExportButton from '../toolbar/ExportButton';
import SyncButton from '../toolbar/SyncButton';
import SwitchGroup from '../group/SwitchGroup';
import { OrderApi } from '../../api/orderApi';
import layoutsApi from '../../api/layoutsApi';
import { getDataset } from '../../models/Datasets';
import { withApollo } from '@apollo/client/react/hoc';
import Filters from '../toolbar/filters/Filters';
import MixpanelLogger from '../../diagnostics/logging/mixpanelLogger';
import {
    getCreatedLayoutPropertiesForMixpanel,
    sendSaveLayoutMixpanelEvent,
} from '../../tools/LayoutTools';
import isNewEntity from '_legacy/data/isNewEntity';
import { intervalOfHidingOldConvertedOrdersInMinutes } from '../../config';
import GroupingOrdersToolPanel from '../toolPanels/grouping/GroupingOrdersToolPanel';
import { getDefaultOrdersRowGroupsSorting } from '../../services/RowGroupsSortingService';
import { getDefaultOrdersRowGroups } from '../../services/RowGroupsService';
import cloneDeep from 'lodash/cloneDeep';
import { ChipOutlined } from '../ui/ChipOutlined';
import { compareColumnPropertyState } from '../common/gridColumnsUtils';
import LayoutsDropdownMenu from 'components/toolbar/LayoutsDropdownMenu';
import { changeCollapsedRowGroups } from 'store/feature/layoutsSlice';

const cleanseTypename = (obj) => {
    if (!obj) {
        return;
    }

    if (obj.hasOwnProperty('__typename')) {
        delete obj['__typename'];
    }

    for (const prop in obj) {
        if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object')
            cleanseTypename(obj[prop]);
    }
};

const CancelToken = Axios.CancelToken;

export class OrdersPage extends Component {
    constructor(props) {
        super(props);
        this.showCondensedToggleRef = React.createRef();
        this.openSidebar = this.openSidebar.bind(this);
        this.handleSidebarClickOutside =
            this.handleSidebarClickOutside.bind(this);
        this.basicGridRef = React.createRef();
        this.clearFilters = this.clearFilters.bind(this);
        this.handleNewOrder = this.handleNewOrder.bind(this);
        this.onLayoutAdded = this.onLayoutAdded.bind(this);
        this.handleNewOrderAdded = this.handleNewOrderAdded.bind(this);
        this.handleOrderUpdated = this.handleOrderUpdated.bind(this);
        this.reinstate = this.reinstate.bind(this);
        this.removeSubscriptions = this.removeSubscriptions.bind(this);
        this.setupSubscriptions = this.setupSubscriptions.bind(this);
        this.handleGridReady = this.handleGridReady.bind(this);
        this.handleGridOptionsChanged =
            this.handleGridOptionsChanged.bind(this);
        this.refreshLayout = this.refreshLayout.bind(this);
        this.resetAddedSessionIndex = this.resetAddedSessionIndex.bind(this);
        this.handleOpenAuditActivity = this.handleOpenAuditActivity.bind(this);
        this.toggleActivity = this.toggleActivity.bind(this);
        this.makePublic = this.makePublic.bind(this);
        this.makePrivate = this.makePrivate.bind(this);
        this.makeUnRumoured = this.makeUnRumoured.bind(this);
        this.makeRumoured = this.makeRumoured.bind(this);
        this.delete = this.delete.bind(this);
        this.share = this.share.bind(this);
        this.shareToGroup = this.shareToGroup.bind(this);
        this.convertToFixture = this.convertToFixture.bind(this);

        this.handleOrderShared = this.handleOrderShared.bind(this);
        this.updatePrivateFields = this.updatePrivateFields.bind(this);
        this.updateRumouredFields = this.updateRumouredFields.bind(this);

        this.toggleShowDeleted = this.toggleShowDeleted.bind(this);
        this.toggleHighlightNewOrders =
            this.toggleHighlightNewOrders.bind(this);
        this.toggleShowConvertedOrders =
            this.toggleShowConvertedOrders.bind(this);
        this.toggleOnlyDisplayRumouredOrders =
            this.toggleOnlyDisplayRumouredOrders.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);

        this.toggleShowCondensed = this.toggleShowCondensed.bind(this);
        this.toggleAddLayoutDialog = this.toggleAddLayoutDialog.bind(this);
        this.onResetLayout = this.onResetLayout.bind(this);

        //this.loadOrders = this.loadOrders.bind(this);

        //hold initial grid info in state so we don't keep re-rendering
        //the grid everytime they change a column/filter/sort/group
        //
        const { creationIndex: createdIndex, exportParams } = this.props;

        this.state = {
            orderToConvert: undefined,
            sidebarOpen: false,
            queuedUpdates: [],
            queuedAdds: [],
            gridReady: false,
            itemAddedSessionIndex: null,
            createdIndex,
            export: exportParams,
            exportColIdsToSkip: ['privacyWarning', 'isSelected', 'privacy'],
            activity: {},
            selectedOrders: [],
            showDeleted: fetchShowDeletedOrders(),
            showAddLayout: false,
            layoutWasReset: false,
            ordersLoaded: false,
        };

        this.setValueSetter();

        this.cancelSource = null;
    }

    handleGridReady() {
        this.setState({
            gridReady: true,
        });

        this.setState((state) => {
            for (let index = 0; index < state.queuedAdds.length; index++) {
                const order = state.queuedAdds[index];
                this.handleNewOrderAdded(order);
            }

            for (let index = 0; index < state.queuedUpdates.length; index++) {
                const order = state.queuedUpdates[index];
                this.handleOrderUpdated(order);
            }

            return {
                queuedUpdates: [],
                queuedAdds: [],
            };
        });
    }

    removeSubscriptions() {
        if (this.orderAddedSubscription) {
            this.orderAddedSubscription.unsubscribe();
        }
        if (this.orderUpdatedSubscription) {
            this.orderUpdatedSubscription.unsubscribe();
        }
        if (this.orderSharedSubscription) {
            this.orderSharedSubscription.unsubscribe();
        }
    }

    setupSubscriptions() {
        this.removeSubscriptions();
        if (this.props.groupId) {
            this.orderAddedSubscription = graphQlClient()
                .subscribe({
                    query: ORDER_ADDED_SUBSCRIPTION,
                    variables: {
                        groupId: this.props.groupId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleNewOrderAdded,
                    error(err) {
                        logger.warn(
                            'SUBSCRIPTION ORDER ERROR ADDED' + err.message
                        );
                    },
                });

            this.orderUpdatedSubscription = graphQlClient()
                .subscribe({
                    query: ORDER_UPDATED_SUBSCRIPTION,
                    variables: {
                        groupId: this.props.groupId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleOrderUpdated,
                    error(err) {
                        logger.warn(
                            'SUBSCRIPTION ORDER ERROR UPDATED' + err.message
                        );
                    },
                });

            this.orderSharedSubscription = graphQlClient()
                .subscribe({
                    query: ORDER_SHARED_SUBSCRIPTION,
                    variables: {
                        groupId: this.props.groupId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleOrderShared,
                    error(err) {
                        logger.warn(
                            'SUBSCRIPTION ORDER ERROR SHARED' + err.message
                        );
                    },
                });
        }
    }

    createDataResetState() {
        return {
            loadingOrders: false,
            ordersLoaded: false,
            ordersLoadedDatasetId: null,
            orderLoadingError: null,
            orders: null,
        };
    }

    componentDidMount() {
        if (this.props.groupId) {
            this.loadOrders();
        }

        this.setupSubscriptions();
    }

    componentWillUnmount() {
        this.removeSubscriptions();
    }

    loadOrders() {
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }

        if (this.props.gridData) {
            this.setState(this.createDataResetState());
            return;
        }

        this.cancelSource = CancelToken.source();

        this.setState((state, props) => {
            OrderApi.fetchOrders(
                this.props.groupId,
                0, // as we don't need separation be years now, we always get snapshot for all time
                this.cancelSource.token,
                this.state.showDeleted || false,
                this.props.selectedLayout.orders.currentOptions
                    .shouldShowConvertedOrders,
                this.props.selectedLayout.orders.currentOptions
                    .shouldOnlyDisplayRumouredOrders
            ).then(
                (results) => {
                    this.setState({
                        loadingOrders: false,
                        ordersLoaded: true,
                        ordersLoadedDatasetId: props.datasetId,
                        orderLoadingError: null,
                        orders: results,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        loadingOrders: false,
                        ordersLoaded: false,
                        ordersLoadedDatasetId: null,
                        orderLoadingError: error,
                        orders: null,
                    });
                }
            );
            return {
                loadingOrders: true,
                ordersLoaded: false,
                ordersLoadedDatasetId: null,
                orderLoadingError: null,
                orders: null,
            };
        });
    }

    onLayoutAdded(newLayout) {
        const localGridOptions = this.getMixpanelGridOptions();
        this.sendCreateLayoutMixpanelEvent(
            newLayout.name,
            'Order',
            newLayout.orders,
            newLayout.commonSettings.directionLogic,
            newLayout.commonSettings.quantityFormat,
            localGridOptions
        );

        const fixturesGridOptions = this.getMixpanelFixturesGridOptions();
        this.sendCreateLayoutMixpanelEvent(
            newLayout.name,
            'Fixture',
            newLayout.fixtures,
            newLayout.commonSettings.directionLogic,
            newLayout.commonSettings.quantityFormat,
            fixturesGridOptions
        );
    }

    getCurrentLayout = () => {
        return this.props.allLayouts.find(
            (l) => l.id === this.props.selectedLayout.selectedLayoutId
        );
    };

    handleNewOrder(data, startEditing = true) {
        const addRowFunction = () => {
            if (this.basicGridRef.current) {
                this.basicGridRef.current.addRow(data, startEditing);
            }
        };

        if (this.props.userId === data.createdBy.userId) {
            // Only apply sorting indexes if the order was created by the user
            data.createdIndex = this.state.createdIndex;
            const newCreatedIndex = this.state.createdIndex + 1;

            const newSessionIndex =
                this.state.itemAddedSessionIndex !== null
                    ? this.state.itemAddedSessionIndex
                    : data.createdIndex;
            this.setState(
                {
                    createdIndex: newCreatedIndex,
                    itemAddedSessionIndex: newSessionIndex,
                },
                () => {
                    addRowFunction();
                    this.props.actions.userCreationIndexSet(newCreatedIndex);
                }
            );
        } else {
            addRowFunction();
        }
    }

    toggleShowCondensed(showCondensed) {
        if (this.props.onToggleCondensedView) {
            this.props.onToggleCondensedView(showCondensed);
        }
    }

    toggleShowDeleted(showDeleted) {
        const { shouldOnlyDisplayRumouredOrders } =
            this.props.selectedLayout.orders.currentOptions;

        saveShowDeletedOrders(showDeleted);
        this.setState({ showDeleted }, () => {
            shouldOnlyDisplayRumouredOrders
                ? this.handleGridOptionsChanged({
                      shouldOnlyDisplayRumouredOrders: false,
                  })
                : //this will trigger a refresh of the grid
                  this.loadOrders();
        });

        MixpanelLogger.trackDataFilterToggle(
            'Orders',
            this.props.datasetId,
            this.getSelectedGroup().name,
            'Show Deleted & Withdrawn',
            showDeleted
        );
    }

    toggleHighlightNewOrders() {
        const { shouldHighlightNewOrders } =
            this.props.selectedLayout.orders.currentOptions;

        const newValue = !shouldHighlightNewOrders;
        this.handleGridOptionsChanged({
            shouldHighlightNewOrders: newValue,
        });

        MixpanelLogger.trackDataFilterToggle(
            'Orders',
            this.props.datasetId,
            this.getSelectedGroup().name,
            'Highlight New',
            newValue
        );
    }

    toggleShowConvertedOrders() {
        const { shouldShowConvertedOrders, shouldOnlyDisplayRumouredOrders } =
            this.props.selectedLayout.orders.currentOptions;

        const newValue = !shouldShowConvertedOrders;
        const gridOptions = shouldOnlyDisplayRumouredOrders
            ? {
                  shouldShowConvertedOrders: newValue,
                  shouldOnlyDisplayRumouredOrders: false,
              }
            : { shouldShowConvertedOrders: newValue };

        this.handleGridOptionsChanged(gridOptions);

        MixpanelLogger.trackDataFilterToggle(
            'Orders',
            this.props.datasetId,
            this.getSelectedGroup().name,
            'Show Converted',
            newValue
        );
    }

    toggleOnlyDisplayRumouredOrders() {
        const { shouldOnlyDisplayRumouredOrders } =
            this.props.selectedLayout.orders.currentOptions;

        if (shouldOnlyDisplayRumouredOrders) {
            this.handleGridOptionsChanged({
                shouldOnlyDisplayRumouredOrders: false,
            });
        } else {
            this.handleGridOptionsChanged({
                shouldOnlyDisplayRumouredOrders: true,
                shouldShowConvertedOrders: false,
            });
            saveShowDeletedOrders(false);
            this.setState({ showDeleted: false });
        }

        MixpanelLogger.trackDataFilterToggle(
            'Orders',
            this.props.datasetId,
            this.getSelectedGroup().name,
            'Show Rumoured Only',
            !shouldOnlyDisplayRumouredOrders
        );
    }

    handleNewOrderAdded(data) {
        const {
            data: { orderAdded: order },
        } = data;

        if (order.datasetId !== this.props.datasetId) {
            //do nothing
            return;
        }

        //let the basic grid work out what to do with it
        if (this.state.gridReady) {
            this.handleNewOrder(order, false);
        } else {
            this.setState((state) => {
                return {
                    queuedAdds: [...state.queuedAdds, data],
                };
            });
        }
    }

    handleOrderUpdated(data) {
        const {
            data: { orderUpdated: order },
        } = data;
        if (order.datasetId !== this.props.datasetId) {
            //do nothing
            return;
        }

        //let the basic grid work out what to do with it
        if (this.state.gridReady) {
            if (
                (order.state === 'Deleted' && !this.state.showDeleted) ||
                (order.state === 'Converted' &&
                    !this.props.selectedLayout.orders.currentOptions
                        .shouldShowConvertedOrders)
            ) {
                this.basicGridRef.current.rowDeleted(order);
            } else {
                this.basicGridRef.current.rowUpdated(order);
            }
        } else {
            this.setState((state) => {
                return {
                    queuedUpdates: [...state.queuedUpdates, data],
                };
            });
        }
    }

    handleOpenAuditActivity() {
        this.toggleActivity({ selectedTab: ActivityTabs.AUDIT });
    }

    handleOrderShared(data) {
        const {
            data: { orderShared: order },
        } = data;

        this.basicGridRef.current.rowUpdated(order);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.datsetId !== prevProps.datasetId ||
            this.props.selectedLayout.selectedLayoutId !==
                prevProps.selectedLayout.selectedLayoutId
        ) {
            this.resetRowGroups();
        }

        if (
            this.props.groupId !== prevProps.groupId ||
            this.state.layoutWasReset ||
            this.props.selectedLayout.orders.currentOptions
                .shouldShowConvertedOrders !==
                prevProps.selectedLayout.orders.currentOptions
                    .shouldShowConvertedOrders ||
            this.props.selectedLayout.orders.currentOptions
                .shouldOnlyDisplayRumouredOrders !==
                prevProps.selectedLayout.orders.currentOptions
                    .shouldOnlyDisplayRumouredOrders
        ) {
            const newState = {
                layoutWasReset: false,
                queuedAdds: [],
                queuedUpdates: [],
            };

            this.setState(newState);
            this.setValueSetter();
            this.setupSubscriptions();
            this.loadOrders();
        } else if (this.exportSelectedChanged()) {
            this.setState({ export: this.props.exportParams });
        }
    }

    resetRowGroups = () => {
        const defaultRowGroups = getDefaultOrdersRowGroups(
            this.props.datasetId
        );
        const currentRowGroups =
            this.props.selectedLayout.orders.currentOptions.rowGroups;

        const rowGroupsToAdd = defaultRowGroups.filter(
            (defaultRowGroup) =>
                !currentRowGroups
                    .map((currentRowGroup) => currentRowGroup.colId)
                    .includes(defaultRowGroup.colId)
        );
        const rowGroupColdIdsToRemove = currentRowGroups
            .filter(
                (currentRowGroup) =>
                    !defaultRowGroups
                        .map((defaultRowGroup) => defaultRowGroup.colId)
                        .includes(currentRowGroup.colId)
            )
            .map((rowGroup) => rowGroup.colId);

        if (rowGroupsToAdd.length > 0 || rowGroupColdIdsToRemove.length > 0) {
            let resultRowGroups = currentRowGroups.concat(rowGroupsToAdd);

            const defaultRowGroupSorting = getDefaultOrdersRowGroupsSorting(
                this.props.datasetId,
                this.props.selectedLayout.commonSettings.directionLogic
                    .currentState
            );
            let resultRowGroupSorting = cloneDeep(
                this.props.selectedLayout.orders.currentOptions.rowGroupsSorting
            );

            for (const rowGroup of rowGroupsToAdd) {
                const rowGroupSortingToAdd = defaultRowGroupSorting.find(
                    (groupSorting) => groupSorting.colId === rowGroup.colId
                );

                if (rowGroupSortingToAdd) {
                    resultRowGroupSorting.push(rowGroupSortingToAdd);
                }
            }

            resultRowGroups = resultRowGroups.filter(
                (rowGroup) => !rowGroupColdIdsToRemove.includes(rowGroup.colId)
            );
            resultRowGroupSorting = resultRowGroupSorting.filter(
                (rowGroupSorting) =>
                    !rowGroupColdIdsToRemove.includes(rowGroupSorting.colId)
            );

            this.handleGridOptionsChanged({
                rowGroupsSorting: resultRowGroupSorting,
                rowGroups: resultRowGroups,
            });
        }
    };

    exportSelectedChanged = () =>
        this.props.exportParams.fixtures.length !==
            this.state.export.fixtures.length ||
        this.props.exportParams.orders.length !==
            this.state.export.orders.length;

    getOrderId = (order) => order.id || order.shadowId;

    setValueSetter() {
        const { userId, username, groupId } = this.props;
        this.orderValueSetter = createGridCellValueSetter({
            username,
            userId,
            groupId,
            createMutation: createOrderFieldMutation,
        });
    }

    generateFixtureSeed(order) {
        if (order) {
            let laycanShorthand = '';
            if (order.laycan) {
                laycanShorthand = order.laycan.shorthand;
            }
            const seed = {
                orderId: order.id,
                cargoQuantityParts: order.cargoQuantityParts,
                cargo: order.cargo,
                charterer: order.charterer,
                loadLocationParts: order.loadLocationParts,
                dischargeLocationParts: order.dischargeLocationParts,
                laycanShorthand,
                type: order.type,
                comments: order.comments,
                brokers: order.brokers,
                rumoured: order.rumoured,
                privateComments: order.privateComments,
                privacy: order.privacy,
                privateFields: order.privateFields,
            };

            cleanseTypename(seed);

            return seed;
        }

        return undefined;
    }

    openSidebar() {
        this.setState({
            sidebarOpen: true,
        });
    }

    clearFilters() {
        if (this.basicGridRef.current) {
            this.basicGridRef.current.clearFilters();
        }
    }

    refreshLayout() {
        this.resetAddedSessionIndex(true);
    }

    onResetLayout() {
        this.setState({ layoutWasReset: true });
    }

    toggleAddLayoutDialog() {
        this.setState((prevState) => ({
            showAddLayout: !prevState.showAddLayout,
        }));
    }

    resetAddedSessionIndex(forceRefreshGrid = false) {
        if (this.state.itemAddedSessionIndex !== null) {
            this.setState(
                {
                    itemAddedSessionIndex: null,
                },
                () => {
                    if (this.basicGridRef.current) {
                        this.basicGridRef.current.refreshRowModel();
                    }
                }
            );
        } else if (forceRefreshGrid && this.basicGridRef.current) {
            this.basicGridRef.current.refreshRowModel();
        }
    }

    handleSidebarClickOutside() {
        this.setState({
            sidebarOpen: false,
        });
    }

    toggleSlide = (name) => {
        const isOpen = this.state.activeSlide === name;

        this.setState({ activeSlide: isOpen ? null : name });
    };

    toggleActivity = ({ selectedTab } = {}) => {
        if (selectedTab) {
            this.setState({
                activity: {
                    selectedTab,
                },
            });
        }

        this.toggleSlide('activity');
    };

    makePublic(order) {
        this.props.client
            .mutate({
                mutation: MAKE_ORDER_PUBLIC_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: order.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const updatedOrder = result.data.makeOrderPublic;
                order.privacy = updatedOrder.privacy;
                order.privateFields = updatedOrder.privateFields;
                order.lastUpdatedDateTimeByUser =
                    updatedOrder.lastUpdatedDateTimeByUser;
                order.lastUpdatedByUser = updatedOrder.lastUpdatedByUser;
                order.status = updatedOrder.status;
                this.basicGridRef.current.rowUpdated(order);
            });
    }

    makePrivate(order) {
        this.props.client
            .mutate({
                mutation: MAKE_ORDER_PRIVATE_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: order.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const updatedOrder = result.data.makeOrderPrivate;
                order.privacy = updatedOrder.privacy;
                order.privateFields = updatedOrder.privateFields;
                order.lastUpdatedDateTimeByUser =
                    updatedOrder.lastUpdatedDateTimeByUser;
                order.lastUpdatedByUser = updatedOrder.lastUpdatedByUser;
                order.status = updatedOrder.status;
                this.basicGridRef.current.rowUpdated(order);
            });
    }

    makeUnRumoured(order) {
        const variables = {
            groupId: this.props.groupId,
            id: order.id,
        };

        OrderApi.makeUnRumoured(this.props.client, variables).then(
            (updatedOrder) => {
                order.rumoured = updatedOrder.rumoured;
                order.lastUpdatedDateTimeByUser =
                    updatedOrder.lastUpdatedDateTimeByUser;
                order.lastUpdatedByUser = updatedOrder.lastUpdatedByUser;

                if (
                    !this.props.selectedLayout.orders.currentOptions
                        .shouldOnlyDisplayRumouredOrders
                ) {
                    this.basicGridRef.current.rowUpdated(order);
                } else {
                    this.basicGridRef.current.rowDeleted(order);
                }
            }
        );
    }

    makeRumoured(order) {
        const variables = {
            groupId: this.props.groupId,
            id: order.id,
        };

        OrderApi.makeRumoured(this.props.client, variables).then(
            (updatedOrder) => {
                order.rumoured = updatedOrder.rumoured;
                order.lastUpdatedDateTimeByUser =
                    updatedOrder.lastUpdatedDateTimeByUser;
                order.lastUpdatedByUser = updatedOrder.lastUpdatedByUser;
                this.basicGridRef.current.rowUpdated(order);
            }
        );
    }

    delete(order) {
        this.props.client
            .mutate({
                mutation: DELETE_ORDER_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: order.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                if (this.state.showDeleted) {
                    this.basicGridRef.current.rowUpdated(
                        result.data.deleteOrder
                    );
                } else {
                    this.basicGridRef.current.rowDeleted(
                        result.data.deleteOrder
                    );
                }
            });
    }

    makeWithdrawn = (order) => {
        const variables = {
            groupId: this.props.groupId,
            id: order.id,
        };

        OrderApi.makeWithdrawn(this.props.client, variables).then(
            (updatedOrder) => {
                if (this.state.showDeleted) {
                    this.basicGridRef.current.rowUpdated(updatedOrder);
                } else {
                    this.basicGridRef.current.rowDeleted(updatedOrder);
                }
            }
        );
    };

    makeNew = (order) => {
        const variables = {
            groupId: this.props.groupId,
            id: order.id,
        };

        OrderApi.makeNew(this.props.client, variables).then((updatedOrder) => {
            this.basicGridRef.current.rowUpdated(updatedOrder);
        });
    };

    makeActive = (order) => {
        const variables = {
            groupId: this.props.groupId,
            id: order.id,
        };

        OrderApi.makeActive(this.props.client, variables).then(
            (updatedOrder) => {
                this.basicGridRef.current.rowUpdated(updatedOrder);
            }
        );
    };

    convertToFixture(order) {
        this.props.client
            .mutate({
                mutation: CONVERT_ORDER_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: order.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                this.sendConvertToFixtureEvent(order, result);

                if (
                    this.props.selectedLayout.orders.currentOptions
                        .shouldShowConvertedOrders
                ) {
                    this.basicGridRef.current.rowUpdated(
                        result.data.convertOrderToFixture.order
                    );
                } else {
                    this.basicGridRef.current.rowDeleted(
                        result.data.convertOrderToFixture.order
                    );
                }
            });
    }

    sendConvertToFixtureEvent(order, result) {
        const { id, privacy, groupOnly } = order;
        const fixtureId = result.data.convertOrderToFixture.fixtureId;
        const { name } = this.getSelectedGroup();

        MixpanelLogger.trackConvertOrderToFixtureEvent(
            groupOnly,
            privacy,
            fixtureId,
            id,
            name
        );
    }

    share(order) {
        this.props.client
            .mutate({
                mutation: SHARE_ORDER_MUTATION,
                variables: {
                    id: order.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                this.basicGridRef.current.rowUpdated(result.data.shareOrder);
            });
    }

    shareToGroup(order) {
        this.props.client
            .mutate({
                mutation: SHARE_ORDER_TO_GROUP_MUTATION,
                variables: {
                    id: order.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                if (this.props.groupId !== order.groupId) {
                    this.basicGridRef.current.rowDeleted(
                        result.data.shareOrderToGroup
                    );
                } else {
                    this.basicGridRef.current.rowUpdated(
                        result.data.shareOrderToGroup
                    );
                }
            });
    }

    updatePrivateFields(order, fields) {
        this.props.client
            .mutate({
                mutation: UPDATE_ORDER_PRIVATE_FIELDS_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: order.id,
                    fields: fields,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const updatedOrder = result.data.updateOrderPrivateFields;
                order.privateFields = updatedOrder.privateFields;
                order.privacy = updatedOrder.privacy;
                order.lastUpdatedDateTimeByUser =
                    updatedOrder.lastUpdatedDateTimeByUser;
                order.lastUpdatedByUser = updatedOrder.lastUpdatedByUser;
                this.basicGridRef.current.rowUpdated(order);
            });
    }

    updateRumouredFields(order, fields) {
        const variables = {
            groupId: this.props.groupId,
            id: order.id,
            fields: fields,
        };

        OrderApi.updateRumouredFields(this.props.client, variables).then(
            (updatedOrder) => {
                order.rumoured = updatedOrder.ruomured;
                order.rumouredFields = updatedOrder.rumouredFields;
                order.lastUpdatedDateTimeByUser =
                    updatedOrder.lastUpdatedDateTimeByUser;
                order.lastUpdatedByUser = updatedOrder.lastUpdatedByUser;

                if (
                    !this.props.selectedLayout.orders.currentOptions
                        .shouldOnlyDisplayRumouredOrders
                ) {
                    this.basicGridRef.current.rowUpdated(order);
                } else {
                    this.basicGridRef.current.rowDeleted(order);
                }
            }
        );
    }

    cloneOrder = (order) => {
        const variables = {
            groupId: this.props.groupId,
            id: order.id,
        };

        OrderApi.cloneOrder(this.props.client, variables).then((newOrder) => {
            const { id, groupOnly, privacy, groupName } = newOrder;

            MixpanelLogger.trackNewOrderEvent(
                id,
                groupOnly,
                privacy,
                groupName,
                'Copied'
            );
            this.basicGridRef.current.addRow(newOrder);
        });
    };

    getSelectedCount() {
        let total =
            this.state.export.orders.length + this.state.export.fixtures.length;

        let bothText =
            this.state.export.orders.length !== 0 &&
            this.state.export.fixtures.length !== 0
                ? 'BOTH '
                : '';

        return total !== 0 ? bothText + '(' + total + ')' : '';
    }

    handleSelectionChanged() {
        setTimeout(() => {
            if (this.basicGridRef.current) {
                const selected = this.basicGridRef.current.getSelectedNodes();

                // WARNING: react-grid exports only visible nodes, and the only indication I found that a node is not rendered is rowTop === null, this is not documented behaviour
                const visibleSelected = selected.filter(
                    ({ rowTop }) => rowTop !== null
                );

                const containsPrivate = visibleSelected.some(
                    (node) => node.data.privacy
                );

                const containsPublic = visibleSelected.some(
                    (node) => !node.data.privacy
                );

                this.setState({
                    selectedOrders: visibleSelected,
                });

                if (this.props.onSelectionChanged) {
                    this.props.onSelectionChanged({
                        ...this.state.export,
                        includesNonConfidentialOrders: containsPublic,
                        includesConfidentialOrders: containsPrivate,
                        orders: visibleSelected,
                    });
                }
            }
        });
    }

    exportToExcel(shouldIncludeRow, shouldExportGroupHeadings) {
        if (this.basicGridRef.current) {
            this.basicGridRef.current.export(
                shouldIncludeRow,
                shouldExportGroupHeadings
            );
        }
    }

    getAuditParams() {
        let displayedColumns = [];
        const { filterOptions } =
            this.props.selectedLayout.orders.currentOptions;
        const { groupId } = this.props;

        if (this.basicGridRef.current) {
            displayedColumns = this.basicGridRef.current.getDisplayedColumns();
        }

        return {
            groupId,
            filterOptions,
            displayedColumns,
        };
    }

    isOldConvertedOrder = (order) => {
        const convertedOrdersToBeHideInHours = 24;
        const date = new Date(order.updatedDateTime);
        const timeAfterWhichNeedToHide = new Date(
            date.setHours(date.getHours() + convertedOrdersToBeHideInHours)
        ).getTime();

        return (
            order.state === 'Converted' && timeAfterWhichNeedToHide < Date.now()
        );
    };

    removeOldConvertedOrdersFromTheGrid = (orders) => {
        if (orders && orders.length !== 0) {
            const oldConvertedOrders = orders.filter((order) =>
                this.isOldConvertedOrder(order)
            );
            oldConvertedOrders.forEach((order) =>
                this.basicGridRef.current.rowDeleted(order, false)
            );
        }
    };

    reinstate(order) {
        this.props.client
            .mutate({
                mutation: REINSTATE_ORDER_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: order.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const updatedOrder = result.data.reinstateOrder;
                order.state = updatedOrder.state;
                order.lastUpdatedDateTimeByUser =
                    updatedOrder.lastUpdatedDateTimeByUser;
                order.lastUpdatedByUser = updatedOrder.lastUpdatedByUser;
                this.basicGridRef.current.rowUpdated(order);
            });
    }

    handleCanPerformAction = (action, order) => {
        switch (action) {
            case ACTION_CONVERT_TO_FIXTURE:
                return true;
            case ACTION_OPEN_AUDIT:
                return true;
            case ACTION_REINSTATE:
                return true;
            case ACTION_CLONE_ORDER:
                return true;
            case ACTION_MAKE_NEW:
                return order.state === 'Active' && isNewEntity(order);
            case ACTION_MAKE_ACTIVE:
                return (
                    order.state === 'New' ||
                    order.state === 'Inactive' ||
                    order.state === 'Active'
                );
            case ACTION_WITHDRAWN:
                return true;
            default:
                return false;
        }
    };

    insertDefaultLayoutIfNotExist = () => {
        if (
            this.props.selectedLayout.orders.initialOptions.columnOptions
                .length === 0
        ) {
            const initialOptions =
                this.props.selectedLayout.orders.initialOptions;
            const columnState = this.basicGridRef.current.getColumnState();

            const ordersLayout = {
                filters: { ...initialOptions.filterOptions },
                columnState: [...columnState],
                shouldHighlightNewOrders:
                    initialOptions.shouldHighlightNewOrders,
                shouldShowConvertedOrders:
                    initialOptions.shouldShowConvertedOrders,
                shouldOnlyDisplayRumouredOrders:
                    initialOptions.shouldOnlyDisplayRumouredOrders,
            };

            layoutsApi.persistOrdersLayout(
                this.props.datasetId,
                this.props.groups,
                this.props.allLayouts,
                this.props.selectedLayout.selectedLayoutId,
                ordersLayout,
                this.props.actions.onUpdateLayouts,
                this.props.actions.onSelectLayout
            );
        }
    };

    getSelectedGroup = () => {
        const { groupId, groups } = this.props;
        const groupName = groups.filter((group) => group.id === groupId)[0]
            .name;

        return {
            id: groupId,
            name: groupName,
        };
    };

    trackIfDeliveryTermChanged = (params) => {
        if (params.column.colId !== 'deliveryTerm') {
            return;
        }

        MixpanelLogger.trackDeliveryTermValueChanged(params.newValue);
    };

    render() {
        logger.trackPageView('Orders');

        if (!this.props.groupId) {
            return (
                <SwitchGroup
                    key="SwitchGroup"
                    isAdmin={this.props.isAdmin}
                    onChange={this.props.handleGroupChange}
                />
            );
        }

        const {
            sidebarOpen,
            itemAddedSessionIndex,
            showAddLayout,
            activeSlide,
            loadingOrders: loading,
            orderLoadingError: error,
            ordersLoadedDatasetId,
            ordersLoaded,
            orders,
        } = this.state;

        const { headingDefaults, datasetId } = this.props;
        const dataset = getDataset(datasetId);

        const showActivityTransition = activeSlide === 'activity';
        const showLayoutsManagerTransition = activeSlide === 'layoutManager';

        const renderContent = () => {
            if (error) {
                return <div className="ui error message">{error.message}</div>;
            }
            if (
                (ordersLoadedDatasetId !== datasetId ||
                    loading ||
                    !ordersLoaded) &&
                (ordersLoadedDatasetId !== datasetId || !orders)
            ) {
                return <StripedLoader />;
            }

            const { filterOptions, columnOptions } =
                this.props.selectedLayout.orders.currentOptions;

            const gridProps = {
                rerenderParent: this.forceUpdate.bind(this),
                ref: this.basicGridRef,
                token: datasetId,
                gridId: 'orders',
                selectedGroup: this.getSelectedGroup(),
                shouldHighlightNewOrders:
                    this.props.selectedLayout.orders.currentOptions
                        .shouldHighlightNewOrders,
                shouldShowConvertedOrders:
                    this.props.selectedLayout.orders.currentOptions
                        .shouldShowConvertedOrders,
                headings: headingDefaults.filter((h) =>
                    !dataset.nonAvailableFields.includes(h.colId) ||
                    dataset.nonAvailableOrderFields
                        ? !dataset.nonAvailableOrderFields?.includes(h.colId)
                        : false
                ),
                rowData: orders || [],
                insertDefaultLayoutIfNotExist:
                    this.insertDefaultLayoutIfNotExist,
                onGridReady: this.handleGridReady,
                defaultSetter: (params) => {
                    this.orderValueSetter(params);
                    this.trackIfDeliveryTermChanged(params);
                },
                getDataId: this.getOrderId,
                initialFilterOptions: filterOptions,
                initialColumnOptions: columnOptions,
                userGridOptionsUpdated: this.handleGridOptionsChanged,
                sidebarOpen: sidebarOpen,
                onSidebarClickOutside: this.handleSidebarClickOutside,
                rowClassRules: commonRowAndCellClassRules,
                groupUseEntireRow: true,
                groupSuppressCount: true,
                defaultGroupSortComparator: (nodeA, nodeB) => {
                    return defaultGroupSortComparator(
                        nodeA,
                        nodeB,
                        this.props.selectedLayout.orders.currentOptions
                            .rowGroupsSorting
                    );
                },
                itemAddedSessionIndex: itemAddedSessionIndex,
                skipGroupRowsInExport: true,
                exportColIdsToSkip: this.state.exportColIdsToSkip,
                onMakePublic: this.makePublic,
                onMakePrivate: this.makePrivate,
                onMakeRumoured: this.makeRumoured,
                onMakeUnRumoured: this.makeUnRumoured,
                onDelete: this.delete,
                onMakeWithdrawn: this.makeWithdrawn,
                onMakeNew: this.makeNew,
                onMakeActive: this.makeActive,
                onShare: this.share,
                onShareToGroup: this.shareToGroup,
                onConvertToFixture: this.convertToFixture,
                onUpdatePrivateFields: this.updatePrivateFields,
                onUpdateRumouredFields: this.updateRumouredFields,
                onCloneOrder: this.cloneOrder,
                onSelectionChanged: this.handleSelectionChanged,
                showCondensed: this.props.useCondensedView,
                canPerformAction: this.handleCanPerformAction,
                onOpenAuditActivity: this.handleOpenAuditActivity,
                onReinstate: this.reinstate,
                isAdmin: this.props.isAdmin,
                singleView: this.props.singleView,
                canEdit: this.props.canEdit,
                userTimezone: this.props.userTimezone,
                onCollapsedRowGroupsChanged:
                    this.handleOnCollapsedRowGroupsChanged,
                currentCollapsedRowGroups:
                    this.props.selectedLayout.orders.collapsedRowGroups,
                selectedLayoutId: this.props.selectedLayout.selectedLayoutId,
                currentDirectionLogic:
                    this.props.selectedLayout.commonSettings.directionLogic
                        .currentState,
                currentQuantityFormat:
                    this.props.selectedLayout.commonSettings.quantityFormat
                        .currentState,
                periodicUpdateGrid: this.removeOldConvertedOrdersFromTheGrid,
                updateGridTimeInterval:
                    parseInt(intervalOfHidingOldConvertedOrdersInMinutes) *
                    1000 *
                    60,
                groupingToolPanel: GroupingOrdersToolPanel,
            };

            //return either the original grid (for tests) or the one with the router
            return !this.props.gridWithoutRouter ? (
                <BasicGrid {...gridProps} />
            ) : (
                <BasicGridWithoutRouter {...gridProps} />
            );
        };

        return (
            <div
                className="flex-container"
                style={{
                    height: '100%',
                }}
            >
                <div className="flex-item-fill" style={{ width: '100%' }}>
                    <Toolbar
                        leftMenu={[
                            this.props.canEdit ? (
                                <AddOrder
                                    key="Add Order"
                                    groupId={this.props.groupId}
                                    onCompleted={this.handleNewOrder}
                                    disabled={!this.state.gridReady}
                                />
                            ) : null,
                            <SwitchGroup
                                key="SwitchGroup"
                                isAdmin={this.props.isAdmin}
                                onChange={this.props.handleGroupChange}
                            />,
                            <LayoutsDropdownMenu
                                gridType="orders"
                                onRevert={
                                    this.resetRowGroupColumnsOnRevertLayout
                                }
                            />,
                            <Filters
                                key="Filters"
                                headings={headingDefaults}
                                datasetId={datasetId}
                                initialFilters={
                                    this.props.selectedLayout.orders
                                        .initialOptions.filterOptions
                                }
                                currentFilters={
                                    this.props.selectedLayout.orders
                                        .currentOptions.filterOptions
                                }
                                currentOptions={
                                    this.props.selectedLayout.orders
                                        .currentOptions
                                }
                                onGridOptionsChanged={(
                                    currentLayoutOptions
                                ) => {
                                    this.props.actions.onOrdersGridOptionsChanged(
                                        currentLayoutOptions
                                    );
                                }}
                            />,
                        ]}
                        chips={[
                            this.props.selectedLayout.orders.currentOptions
                                .shouldHighlightNewOrders && (
                                <ChipOutlined
                                    key="highlightNew"
                                    label="HIGHLIGHT NEW"
                                    onDelete={() => {
                                        this.toggleHighlightNewOrders(false);
                                    }}
                                />
                            ),
                            this.props.selectedLayout.orders.currentOptions
                                .shouldShowConvertedOrders && (
                                <ChipOutlined
                                    key="showingConverted"
                                    label="SHOWING CONVERTED"
                                    onDelete={() => {
                                        this.toggleShowConvertedOrders(false);
                                    }}
                                />
                            ),
                            this.state.showDeleted && (
                                <ChipOutlined
                                    key="showingDeletedAndWithdrawn"
                                    label="SHOWING DELETED & WITHDRAWN "
                                    onDelete={() => {
                                        this.toggleShowDeleted(false);
                                    }}
                                />
                            ),
                            this.props.selectedLayout.orders.currentOptions
                                .shouldOnlyDisplayRumouredOrders && (
                                <ChipOutlined
                                    key="displayRumouredOnly"
                                    label="DISPLAY RUMOURED ONLY"
                                    onDelete={() => {
                                        this.toggleOnlyDisplayRumouredOrders(
                                            false
                                        );
                                    }}
                                />
                            ),
                        ]}
                        rightMenuIcons={[
                            this.state.export.orders.length > 0 && (
                                <ExportButton
                                    key="ExportButton"
                                    id="ExportButton"
                                    selectedCount={this.getSelectedCount()}
                                    onClick={this.props.onExportClicked}
                                />
                            ),
                            <SyncButton
                                id="SyncButton"
                                key="SyncButton"
                                onClick={this.refreshLayout}
                            />,
                        ]}
                        rightMenuButtons={[
                            <DataButton
                                key="DataButton"
                                id="DataButton"
                                forFixtures={false}
                                onToggleShowDeleted={this.toggleShowDeleted}
                                showDeleted={this.state.showDeleted}
                                onToggleHighlightNewOrders={
                                    this.toggleHighlightNewOrders
                                }
                                shouldHighlightNewOrders={
                                    this.props.selectedLayout.orders
                                        .currentOptions.shouldHighlightNewOrders
                                }
                                shouldShowConvertedOrders={
                                    this.props.selectedLayout.orders
                                        .currentOptions
                                        .shouldShowConvertedOrders
                                }
                                onToggleShowConvertedOrders={
                                    this.toggleShowConvertedOrders
                                }
                                shouldOnlyDisplayRumouredOrders={
                                    this.props.selectedLayout.orders
                                        .currentOptions
                                        .shouldOnlyDisplayRumouredOrders
                                }
                                onToggleOnlyDisplayRumouredOrders={
                                    this.toggleOnlyDisplayRumouredOrders
                                }
                            />,
                            <MoreButton
                                key="MoreButton"
                                id="MoreButton"
                                singleView={this.props.singleView}
                                ordersPage
                                showCondensed={this.props.useCondensedView}
                                onToggleCondensed={this.toggleShowCondensed}
                                onOpenSideBar={this.openSidebar}
                            />,
                        ]}
                    />
                    <div>
                        <CSSTransition
                            classNames="activity"
                            in={showActivityTransition}
                            timeout={300}
                            unmountOnExit
                        >
                            <Activity
                                onClose={this.toggleActivity}
                                datasetId={datasetId}
                                context={this.props.context}
                                selectedOrders={this.state.selectedOrders}
                                selectedTab={this.state.activity.selectedTab}
                                forFixtures={false}
                            />
                        </CSSTransition>
                    </div>

                    {renderContent.bind(this)()}
                </div>
            </div>
        );
    }

    handleGridOptionsChanged(params) {
        const {
            filterOptions,
            columnOptions,
            shouldHighlightNewOrders,
            shouldShowConvertedOrders,
            shouldOnlyDisplayRumouredOrders,
            shouldResetSessionIndex,
            rowGroupsSorting,
            rowGroups,
        } = params;
        const currentOptions = this.props.selectedLayout.orders.currentOptions;

        const deliveryTermHideState = compareColumnPropertyState(
            'deliveryTerm',
            currentOptions.columnOptions,
            columnOptions,
            'hide'
        );
        if (deliveryTermHideState.hasChanged) {
            MixpanelLogger.trackDeliveryTermVisibilityChange(
                !deliveryTermHideState.newValue
            );
        }

        if (shouldResetSessionIndex) {
            this.resetAddedSessionIndex();
        }

        const currentLayoutOptions = {
            filterOptions: filterOptions ?? currentOptions.filterOptions,
            columnOptions: columnOptions ?? currentOptions.columnOptions,
            shouldHighlightNewOrders:
                shouldHighlightNewOrders ??
                currentOptions.shouldHighlightNewOrders,
            shouldShowConvertedOrders:
                shouldShowConvertedOrders ??
                currentOptions.shouldShowConvertedOrders,
            shouldOnlyDisplayRumouredOrders:
                shouldOnlyDisplayRumouredOrders ??
                currentOptions.shouldOnlyDisplayRumouredOrders,
            rowGroups: rowGroups ?? currentOptions.rowGroups,
            rowGroupsSorting:
                rowGroupsSorting ?? currentOptions.rowGroupsSorting,
        };

        this.props.actions.onOrdersGridOptionsChanged(currentLayoutOptions);
    }

    sendSaveLayoutMixpanelEvent = (
        layoutName,
        gridType,
        layoutItems,
        localGridOptions
    ) => {
        sendSaveLayoutMixpanelEvent(
            layoutName,
            gridType,
            layoutItems,
            localGridOptions,
            this.props.selectedLayout.commonSettings.directionLogic
                .currentState,
            this.props.selectedLayout.commonSettings.quantityFormat
                .currentState,
            this.state.exportColIdsToSkip,
            this.props.datasetId
        );
    };

    handleOnSaveLayout = () => {
        const fixturesOptions =
            this.props.selectedLayout.fixtures.currentOptions;
        const ordersOptions = this.props.selectedLayout.orders.currentOptions;
        const commonSettings = {
            directionLogic:
                this.props.selectedLayout.commonSettings.directionLogic
                    .currentState,
            quantityFormat:
                this.props.selectedLayout.commonSettings.quantityFormat
                    .currentState,
            defaultType:
                this.props.selectedLayout.commonSettings.defaultType
                    .currentState,
        };

        this.saveLayout(
            fixturesOptions,
            ordersOptions,
            this.props.selectedLayout.orders.collapsedRowGroups,
            commonSettings
        );

        const { wereOptionsChanged: wereFixturesOptionsChanged } =
            this.props.selectedLayout.fixtures;
        const { wereOptionsChanged: wereOrdersOptionsChanged } =
            this.props.selectedLayout.orders;
        const currentLayout = this.getCurrentLayout();
        const isDirectionLogicChanged =
            !layoutActions.isCurrentDirectionLogicEqualToInitialOne(
                this.props.selectedLayout.commonSettings.directionLogic
            );
        const isQuantityFormatChanged =
            !layoutActions.isCurrentQuantityFormatEqualToInitialOne(
                this.props.selectedLayout.commonSettings.quantityFormat
            );

        const localGridOptions = this.getMixpanelGridOptions();

        if (
            wereOrdersOptionsChanged ||
            isDirectionLogicChanged ||
            isQuantityFormatChanged
        ) {
            this.sendSaveLayoutMixpanelEvent(
                currentLayout.name,
                'Order',
                this.props.selectedLayout.orders,
                localGridOptions
            );
        }

        if (
            wereFixturesOptionsChanged ||
            isDirectionLogicChanged ||
            isQuantityFormatChanged
        ) {
            this.sendSaveLayoutMixpanelEvent(
                currentLayout.name,
                'Fixture',
                this.props.selectedLayout.fixtures,
                localGridOptions
            );
        }

        this.props.actions.onSaveLayout();
    };

    handleOnRevertLayout = () => {
        const currentLayout = this.getCurrentLayout();
        MixpanelLogger.trackRevertLayoutEvent(currentLayout.name);

        this.props.actions.onRevertLayout();

        this.resetRowGroupColumnsOnRevertLayout();
    };

    resetRowGroupColumnsOnRevertLayout = () => {
        // changes below required just for group values update
        // setTimeout is used to run the function after state being updated
        const currentRowGroupsIds =
            this.props.selectedLayout.orders.initialOptions.rowGroups
                .filter((rg) => rg.checked)
                .map((rg) => rg.colId);
        setTimeout(() =>
            this.basicGridRef.current.resetRowGroupColumns(currentRowGroupsIds)
        );
    };

    handleOnCollapsedRowGroupsChanged = (collapsedRowGroups) => {
        this.props.dispatch(
            changeCollapsedRowGroups({
                gridType: 'orders',
                collapsedRowGroups: collapsedRowGroups,
            })
        );

        // next method should be invoked to recalculate the count of selected rows
        this.handleSelectionChanged();
    };

    saveLayout = (
        fixturesOptions,
        ordersOptions,
        collapsedRowGroups,
        commonSettings
    ) => {
        const fixturesLayout = {
            filters: { ...fixturesOptions.filterOptions },
            columnState: [...fixturesOptions.columnOptions],
            vesselOptions: fixturesOptions.vesselOptions,
            rowGroupsSorting: fixturesOptions.rowGroupsSorting,
            rowGroups: fixturesOptions.rowGroups,
            shouldOnlyDisplayRumouredFixtures:
                fixturesOptions.shouldOnlyDisplayRumouredFixtures,
            collapsedRowGroups:
                this.props.selectedLayout.fixtures.collapsedRowGroups,
        };
        const ordersLayout = {
            filters: { ...ordersOptions.filterOptions },
            columnState: [...ordersOptions.columnOptions],
            shouldHighlightNewOrders: ordersOptions.shouldHighlightNewOrders,
            shouldShowConvertedOrders: ordersOptions.shouldShowConvertedOrders,
            shouldOnlyDisplayRumouredOrders:
                ordersOptions.shouldOnlyDisplayRumouredOrders,
            rowGroupsSorting: ordersOptions.rowGroupsSorting,
            rowGroups: ordersOptions.rowGroups,
            collapsedRowGroups: collapsedRowGroups,
        };

        layoutsApi.persistLayout(
            this.props.datasetId,
            this.props.allLayouts,
            this.props.selectedLayout.selectedLayoutId,
            ordersLayout,
            fixturesLayout,
            commonSettings,
            this.props.actions.onUpdateLayouts
        );
    };

    getMixpanelGridOptions = () => {
        const gridOptions = {
            showDeletedAndWithdrawn: this.state.showDeleted,
        };

        return gridOptions;
    };

    getMixpanelFixturesGridOptions = () => {
        const gridOptions = {
            showDeleted: fetchShowDeletedFixtures(),
        };

        return gridOptions;
    };

    sendCreateLayoutMixpanelEvent = (
        layoutName,
        gridType,
        layoutItems,
        directionLogic,
        quantityFormat,
        gridOptions
    ) => {
        const layoutProperties = getCreatedLayoutPropertiesForMixpanel(
            layoutItems,
            this.state.exportColIdsToSkip,
            gridType,
            directionLogic,
            quantityFormat,
            this.props.datasetId,
            gridOptions
        );
        MixpanelLogger.trackCreateLayoutEvent(layoutName, layoutProperties);
    };
}

const mapStateToProps = (state) => {
    const {
        group,
        groups,
        username,
        userId,
        headingDefaults,
        permissions,
        userTimezone,
    } = state.user;

    const props = {
        selectedLayout: state.layouts.selectedLayout,
        allLayouts: state.layouts.allLayouts,
        headingDefaults: headingDefaults,
        username: username,
        userId: userId,
        userTimezone: userTimezone,
        groupId: group ? group.id : null,
        datasetId: group ? group.datasetId : null,
        groups,
        creationIndex: currentGridCreationIndex(state, ORDER_GRID_TYPE),
        canEdit: permissions.some((p) => p.groupId === group.id && p.canWrite),
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            { ...userActions, ...layoutActions },
            dispatch
        ),
        dispatch,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        actions: {
            ...dispatchProps.actions,
            userChangedGridOptions: (filterOptions, columnOptions) => {
                if (stateProps.groupId) {
                    dispatchProps.actions.userChangedGridOptions(
                        stateProps.groupId,
                        filterOptions,
                        columnOptions,
                        ORDER_GRID_TYPE
                    );
                }
            },
            userCreationIndexSet: (index) => {
                dispatchProps.actions.userCreationIndexSet(
                    index,
                    ORDER_GRID_TYPE
                );
            },
        },
    };
};

OrdersPage = withApollo(OrdersPage, { withRef: true });

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    forwardRef: true,
})(OrdersPage);
