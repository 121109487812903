import { layoutsSelectors } from 'store/feature/layoutsSlice';
import { useAppSelector } from 'store/hooks';
import LayoutIcons from '../common/LayoutIcons';
import { useState } from 'react';
import LayoutManagerPanel from 'components/modal/layouts/manager/LayoutManagerPanel';
import {
    LayoutGridType,
    SavedLayoutModel,
} from 'store/feature/layoutsSlice/models';
import GridTypeContext from 'hooks/common/GridTypeContext';
import LayoutChangedSnackbar from 'components/modal/layouts/dialogs/LayoutChangedSnackbar';
import LayoutControls from 'components/common/LayoutControls';
import {
    Badge,
    Box,
    Button,
    Divider,
    ListItemIcon,
    Menu,
    MenuItem,
    styled,
    Typography,
} from '@mui/material';
import {
    ArrowDropDown,
    CheckCircle,
    Settings,
    Star,
    StarOutline,
} from '@mui/icons-material';
import {
    bindMenu,
    bindTrigger,
    usePopupState,
} from 'material-ui-popup-state/hooks';
import { userSelectors } from 'store/feature/userSlice';
import TinyExpandableArea from 'components/common/TinyExpandableArea';
import useSwitchLayout from 'components/modal/layouts/dialogs/switchLayout';
import useLayoutTitle from 'hooks/common/useLayoutTitle';

const StyledMenu = styled(Menu)(({ theme }) => ({
    maxWidth: '60%',
    '& .menu-title': {
        padding: theme.spacing(1.5, 2),
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 'bold',
        letterSpacing: '.1rem',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
    },
    '& .MuiList-root': {
        minWidth: '400px',
    },
    '& .MuiMenuItem-root.Mui-selected': {
        cursor: 'default',
    },
    '& .MuiDivider-root': {
        margin: theme.spacing(0.3, 0),
    },
    '& .menu-item-text': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 'auto',
        padding: 0,
        margin: 0,
        gap: theme.spacing(1),
    },
    '& .MuiAccordionDetails-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
}));

const UserLayoutMenuItem: React.FC<{
    model: Pick<SavedLayoutModel, 'id' | 'name' | 'isTemplate' | 'isPreferred'>;
    isSelected: boolean;
    hasPendingChanges: boolean;
    onClick: () => void;
    onRevert?: () => void;
}> = ({ model, isSelected, hasPendingChanges, onClick, onRevert }) => {
    const icon = model.isPreferred ? <Star /> : <StarOutline />;
    const handleClick = () => {
        if (!isSelected) {
            onClick();
        }
    };

    return (
        <MenuItem
            dense
            onClick={handleClick}
            disableRipple
            selected={isSelected}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <Box className="menu-item-text">
                <Typography component="div">
                    {hasPendingChanges && isSelected && '*'}
                    {model.name}
                </Typography>
                {isSelected && hasPendingChanges && (
                    <LayoutControls model={model} onRevert={onRevert} />
                )}
            </Box>
        </MenuItem>
    );
};

const TemplateLayoutMenuItem: React.FC<{
    model: Pick<SavedLayoutModel, 'id' | 'name' | 'isTemplate'>;
    isSelected: boolean;
    hasPendingChanges: boolean;
    onClick: () => void;
    onRevert?: () => void;
}> = ({ model, isSelected, hasPendingChanges, onClick, onRevert }) => {
    const handleClick = () => {
        if (!isSelected) {
            onClick();
        }
    };

    return (
        <MenuItem
            dense
            onClick={handleClick}
            disableRipple
            selected={isSelected}
        >
            <ListItemIcon>
                <LayoutIcons.Template />
            </ListItemIcon>
            <Box className="menu-item-text">
                <Typography component="div">
                    {hasPendingChanges && isSelected && '*'}
                    {model.name}
                </Typography>
                {isSelected && hasPendingChanges && (
                    <LayoutControls model={model} onRevert={onRevert} />
                )}
            </Box>
        </MenuItem>
    );
};

const LayoutsButtonStack = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    width: '100%',
    padding: theme.spacing(1),
    '& *': { flex: '1 1 0' },
}));

const TemplateLayoutMenuButton: React.FC<{
    model: Pick<SavedLayoutModel, 'id' | 'name' | 'isTemplate'>;
    isSelected: boolean;
    onClick: () => void;
}> = ({ model, isSelected, onClick }) => {
    const handleClick = () => {
        if (!isSelected) {
            onClick();
        }
    };

    return (
        <Badge
            badgeContent={<CheckCircle />}
            sx={(theme) => ({
                '& .MuiBadge-badge': {
                    color: theme.palette.primary.main,
                    top: '6px',
                    right: '4px',
                    display: isSelected ? 'block' : 'none',
                },
            })}
        >
            <Button
                variant="outlined"
                color={isSelected ? 'primary' : 'inherit'}
                disableRipple
                onClick={handleClick}
                sx={(theme) => ({
                    minHeight: '50px',
                    margin: theme.spacing(0.3, 0),
                    backgroundColor: theme.palette.grey[100],
                    borderWidth: theme.spacing(0.2),
                    '&:not(.MuiButton-colorPrimary)': {
                        borderColor: theme.palette.grey[400],
                    },
                })}
            >
                {model.name}
            </Button>
        </Badge>
    );
};

export interface LayoutsDropdownMenuProps {
    gridType: LayoutGridType; // TODO: After OrdersPage and FixturePage refactoring - move context provider outside
    onRevert: () => void;
}

const LayoutsDropdownMenu: React.FC<LayoutsDropdownMenuProps> = ({
    gridType,
    onRevert,
}) => {
    const isAdmin = useAppSelector(userSelectors.isAdmin);
    const selectedLayout = useAppSelector(layoutsSelectors.selectedLayout);
    const layouts = useAppSelector(layoutsSelectors.selectedDatasetLayouts);

    const userLayouts: SavedLayoutModel[] = [];
    const templateLayouts: SavedLayoutModel[] = [];
    layouts.forEach((l) => {
        if (l.isTemplate) {
            templateLayouts.push(l);
        } else {
            userLayouts.push(l);
        }
    });

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'layout_dropdown_menu',
    });
    const [showLayoutManager, setShowLayoutManager] = useState(false);

    const { switchLayout, switchLayoutDialog } = useSwitchLayout();

    const { icon: triggerIcon, text: triggerText } =
        useLayoutTitle(selectedLayout);

    const menuTitle = gridType + ' layouts';
    const divider = <Divider flexItem />;

    return (
        <GridTypeContext.Provider value={gridType}>
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: theme.spacing(3, 3),
                    '& .MuiButton-root': {},
                    '& .expand-icon': {
                        ...(popupState.isOpen && {
                            transform: 'rotate(180deg)',
                        }),
                    },
                })}
            >
                <Button
                    {...bindTrigger(popupState)}
                    disableElevation
                    disableRipple
                    variant="outlined"
                    color="info"
                    startIcon={triggerIcon}
                    endIcon={<ArrowDropDown className="expand-icon" />}
                >
                    {triggerText}
                </Button>
            </Box>
            <StyledMenu
                {...bindMenu(popupState)}
                MenuListProps={{
                    'aria-labelledby': popupState.popupId,
                    dense: true,
                }}
            >
                <Box className="menu-title">{menuTitle}</Box>
                <TinyExpandableArea
                    idx="user"
                    title="My layouts"
                    defaultExpanded
                >
                    {userLayouts.map((layout) => (
                        <UserLayoutMenuItem
                            model={layout}
                            isSelected={
                                layout.id === selectedLayout.selectedLayoutId
                            }
                            hasPendingChanges={
                                selectedLayout.areThereAnyUnsavedLayoutChanges
                            }
                            onClick={switchLayout.bind(null, layout.id)}
                            onRevert={onRevert}
                        />
                    ))}
                </TinyExpandableArea>

                {templateLayouts.length > 0 && (
                    <>
                        {isAdmin && (
                            <>
                                {divider}
                                <TinyExpandableArea
                                    idx="templates-admin"
                                    title="Layout templates (admin)"
                                >
                                    {templateLayouts.map((layout) => (
                                        <TemplateLayoutMenuItem
                                            model={layout}
                                            isSelected={
                                                layout.id ===
                                                selectedLayout.selectedLayoutId
                                            }
                                            hasPendingChanges={
                                                selectedLayout.areThereAnyUnsavedLayoutChanges
                                            }
                                            onClick={switchLayout.bind(
                                                null,
                                                layout.id
                                            )}
                                            onRevert={onRevert}
                                        />
                                    ))}
                                </TinyExpandableArea>
                            </>
                        )}

                        {divider}
                        <TinyExpandableArea
                            idx="templates"
                            title="Layout templates"
                        >
                            <LayoutsButtonStack>
                                {templateLayouts.map((layout) => (
                                    <TemplateLayoutMenuButton
                                        model={layout}
                                        isSelected={
                                            layout.id ===
                                            selectedLayout.selectedLayoutId
                                        }
                                        onClick={switchLayout.bind(
                                            null,
                                            layout.id
                                        )}
                                    />
                                ))}
                            </LayoutsButtonStack>
                        </TinyExpandableArea>
                    </>
                )}

                {divider}
                <MenuItem
                    dense
                    onClick={() => {
                        setShowLayoutManager(true);
                        popupState.close();
                    }}
                    disableRipple
                >
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    <Box className="menu-item-text">
                        <Typography component="div">Manage layouts</Typography>
                    </Box>
                </MenuItem>
            </StyledMenu>
            {switchLayoutDialog}
            <LayoutManagerPanel
                show={showLayoutManager}
                onClose={() => setShowLayoutManager(false)}
            />
            <LayoutChangedSnackbar />
        </GridTypeContext.Provider>
    );
};

export default LayoutsDropdownMenu;
