import { Box, Chip, styled } from '@mui/material';
import CountryImage from '_legacy/components/common/CountryImage';

interface IThirdPartyCompanySearchResultProps {
    item: any;
    getItemProps: (callerProps: any) => object;
    highlightedIndex: number;
    index: number;
}

const ThirdPartyCompanySearchResult: React.FC<
    IThirdPartyCompanySearchResultProps
> = ({ item, getItemProps, highlightedIndex, index }) => {
    return (
        <tr
            {...getItemProps({
                item,
                key: item.id,
                style: {
                    backgroundColor:
                        highlightedIndex === index
                            ? 'rgba(0,0,0,0.05)'
                            : 'white',
                },
            })}
        >
            <td title={item.name}>
                <Box display="flex" alignItems="center">
                    {item.isNonClient && (
                        <StyledIndicator label="Non-KYC" size="small" />
                    )}
                    {getShortName(item.name)}
                </Box>
            </td>
            <td title={item.parentName}>{getShortName(item.parentName)}</td>
            <td>
                <CountryImage countryCode={item.countryCode} />
            </td>
        </tr>
    );
};

const getShortName = (name) => {
    return name && name.length > 30 ? name.substr(0, 30) + '...' : name;
};

const StyledIndicator = styled(Chip)({
    width: '53px',
    height: '18px',
    fontSize: '10px',
    fontWeight: 400,
    marginRight: '5px',
    backgroundColor: '#CCD0D1',
    '.MuiChip-label': {
        padding: 0,
    },
});

export default ThirdPartyCompanySearchResult;
